import { API_URLS } from "../configs/api.urls";
import { axiosPrivate } from "../configs/axios";

// export const getreviewsByStorageId = async (data) => {
//   return await axiosPrivate({
//     url:
//       API_URLS.GET_REVIEWS_BY_STORAGE_ID_URL +
//       data.storageId +
//       "/" +
//       data.userId,
//     method: "GET",
//   });
// };

export const getreviewsByStorageId = async ({
  storageId,
  userId,
  page,
  limit,
}) => {
  return await axiosPrivate({
    url: `${API_URLS.GET_REVIEWS_BY_STORAGE_ID_URL}/${storageId}/${userId}`,
    method: "GET",
    params: {
      page,
      limit,
    },
  }).then((response) => ({
    data: response.data.success.data,
    nextPage:
      response.data.success.meta.currentPage <
      response.data.success.meta.totalPages
        ? response.data.success.meta.currentPage + 1
        : undefined,
  }));
};

export const addReview = async (data) => {
  return await axiosPrivate({
    url: API_URLS.ADD_REVIEW_URL,
    method: "POST",
    data: data,
  });
};

/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  GoogleMap,
  Marker,
  StandaloneSearchBox,
  useJsApiLoader,
} from "@react-google-maps/api";
import { Form, Input, Layout, Radio, Select, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useRef, useState } from "react";
import { googleMapsConfig } from "../../../../common/configs/googleMap";
import { mapStyles } from "../../../../common/configs/mapStyles";
import "./Step.css";

const { Option } = Select;
const { Content } = Layout;

const InitialStep = ({ form, formData, handleChange }) => {
  const { isLoaded } = useJsApiLoader({
    id: googleMapsConfig.id,
    googleMapsApiKey: googleMapsConfig.googleMapsApiKey,
    libraries: googleMapsConfig.libraries,
  });

  const mapContainerStyle = {
    width: "100%",
    height: "100%",
  };
  const [location, setLocation] = useState({ lat: 0.1276, lng: 51.5072 });
  const [mapAddress, setMapAddress] = useState("");
  const [isHaveInMap, setIsHaveInMap] = useState(false);
  const [countries, setCountries] = useState([]);

  const [markerRef, setMarkerRef] = useState();
  const inputRef = useRef(null);
  const handleMapClick = (e) => {
    const { latLng } = e;
    const lat = latLng.lat();
    const lng = latLng.lng();
    setMarkerRef({ lat: lat, lng: lng });
    sendData("longitude", lng);
    sendData("latitude", lat);
    // Update the state with the clicked coordinate
  };

  useEffect(() => {
    if (markerRef != null) {
      setLocation(markerRef);
    }
  }, [markerRef]);

  const fetchCountries = async () => {
    const countries = [];

    // Google's supported country list
    const supportedCountries = [
      "US",
      "GB",
      "IN",
      "FR",
      "DE",
      "AU",
      "CA",
      "CN",
      "JP",
      "BR",
      "ZA",
      "IT",
      "ES",
      "RU",
      "KR",
      "MX",
      "NL",
      "SE",
      "CH",
      "SG",
    ];

    supportedCountries.forEach((countryCode) => {
      const countryName = new Intl.DisplayNames(["en"], {
        type: "region",
      }).of(countryCode);

      countries.push({
        value: countryCode,
        label: countryName,
      });
    });

    return countries;
  };

  useEffect(() => {
    if (isLoaded) {
      const fetchData = async () => {
        try {
          const countryList = await fetchCountries();
          setCountries(countryList);
        } catch (e) {
          console.error("Error loading Google Maps API:", e);
        }
      };
      fetchData();
    }
  }, [isLoaded]);

  const sendData = (name, value) => {
    // Create a synthetic event object
    const syntheticEvent = {
      target: {
        name: name,
        value: value,
      },
    };
    switch (name) {
      case "address1":
        formData.address1 = value;
        break;
      case "address2":
        formData.address2 = value;
        break;
      case "postalCode":
        formData.postalCode = value;
        break;
      case "country":
        formData.country = value;
        break;
      case "longitude":
        formData.longitude = value;
        break;
      case "latitude":
        formData.latitude = value;
        break;
      case "city":
        formData.city = value;
        break;
      default:
        return;
    }
    form.setFieldsValue({
      address1: formData.address1,
      address2: formData.address2,
      postalCode: formData.postalCode,
      city: formData.city,
      country: formData.country,
    });
    // Call handleChange with the synthetic event
    handleChange(syntheticEvent);
  };

  const getAddressData = (components) => {
    let address1 = "";
    let address2 = "";
    let postalCode = "";
    let country = "";
    let city = "";

    // Loop through the address components and find each part
    components?.forEach((component) => {
      const types = component?.types;
      if (types.includes("street_number") || types.includes("route")) {
        address1 += component?.long_name + " ";
      }
      if (types.includes("locality")) {
        city += " " + component?.long_name;
      }
      if (types.includes("postal_code")) {
        postalCode = component?.long_name;
      }
      if (types.includes("country")) {
        country = component?.long_name;
      }
      if (types.includes("administrative_area_level_1")) {
        city += " " + component?.long_name;
      }
      if (types.includes("postal_town")) {
        address2 += component?.long_name + " ";
      }
    });

    // Return the address data as an object
    return {
      address1: address1.trim(),
      address2: address2.trim(),
      postalCode: postalCode.trim(),
      city: city.trim(),
      country: country.trim(),
    };
  };

  const handlePlaceChanged = () => {
    if (inputRef.current) {
      const place = inputRef.current.getPlaces()[0];

      const selectedAddress = place.formatted_address || place.name;
      setMapAddress(selectedAddress);
      // Extract components of the address
      const addressComponents = place.address_components
        ? place.address_components
        : null;
      const addressData = getAddressData(addressComponents);

      const lat = place.geometry?.location?.lat();
      const lng = place.geometry?.location?.lng(); // Use formatted_address if available
      // setAddress(addressData);
      // setLocation({
      //   lat: lat,
      //   lng: lng,
      // });

      sendData("longitude", lng);
      sendData("latitude", lat);
      sendData("address1", addressData.address1);
      sendData("address2", addressData.address2);
      sendData("postalCode", addressData.postalCode);
      sendData("city", addressData.city);
      sendData("country", addressData.country);
      setMarkerRef({ lat: lat, lng: lng });
    }
  };

  const onChange = (e) => {
    setIsHaveInMap(e.target.value);
  };

  return (
    <div className="initial-step-form">
      <Typography.Title level={3}>Store Location</Typography.Title>
      {/* Location Search */}
      <Form.Item>
        {isLoaded && (
          <StandaloneSearchBox
            onLoad={(ref) => (inputRef.current = ref)}
            onPlacesChanged={handlePlaceChanged}
          >
            <Input
              onChange={(e) => setMapAddress(e.target.value)}
              value={mapAddress}
              placeholder="Enter location"
              style={{ borderRadius: 8 }}
            />
          </StandaloneSearchBox>
        )}
      </Form.Item>

      <Form.Item
        style={{ display: "flex" }}
        name="googleMap"
        label="Is your store located on Google Maps?"
      >
        <Radio.Group onChange={onChange} value={isHaveInMap}>
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </Form.Item>

      <Content
        // className={`map-container map-view`}
        style={{ width: "100%", height: "400px", padding: 0 }}
      >
        {/*<LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}>*/}
        {isLoaded && (
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={location}
            zoom={9}
            options={{
              styles: mapStyles, // Apply custom styles here
              disableDefaultUI: true, // Disables default UI like zoom controls, map type selector
            }}
            onClick={handleMapClick}
          >
            {markerRef && <Marker position={markerRef} />}
          </GoogleMap>
        )}
        {/*</LoadScript>*/}
      </Content>

      {/* Address Fields */}
      {/*{!isHaveInMap && (*/}
      <>
        <Form.Item name="address1" label="Address 1">
          <Input placeholder="Address 1" style={{ borderRadius: 8 }} />
        </Form.Item>
        <Form.Item name="address2" label="Address 2">
          <Input placeholder="Address 2" style={{ borderRadius: 8 }} />
        </Form.Item>
        <Form.Item name="postalCode" label="Postal Code">
          <Input placeholder="Postal Code" style={{ borderRadius: 8 }} />
        </Form.Item>
        {/* Country Selector */}
        <Form.Item name="country" label="Country">
          <Select
            placeholder="Select a country"
            style={{ borderRadius: 8 }}
            showSearch
          >
            {countries.length > 0 &&
              countries.map((country) => (
                <Option key={country.value} value={country.value}>
                  {country.label}
                </Option>
              ))}
            {/* Add more countries as needed */}
          </Select>
        </Form.Item>{" "}
      </>
      {/*)}*/}

      {/* Description */}
      <Form.Item name="description" label="Description">
        <TextArea
          rows={4}
          placeholder="Additional details of the located store for direction"
          style={{ borderRadius: 8 }}
        />
      </Form.Item>

      {/*</Form>*/}
    </div>
  );
};

export default InitialStep;

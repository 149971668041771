/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useState } from "react";
import { Card, Col, Row, Statistic } from "antd";
import DropdownOption from "../../../common/components/dropdown-option/DropdownOption";
import UserBooking from "../bookingcard/UserBooking";
import CreateStore from "../store/create/CreateStore";
import { useQuery } from "@tanstack/react-query";
import { getAllBooking } from "../../../common/api/booking";
import { getAllStorage } from "../../../common/api/storage";
import moment from "moment";
import "./AdminDashboard.css";
import Icon from "antd/es/icon";
import { getStatistics } from "../../../common/api/statistics";
import UserMenu from "../../../common/components/menu/UserMenu";

const AdminDashboard = ({ setActiveTabKey }) => {
  const bookingQuery = useQuery({
    queryKey: ["booking", "all"],
    queryFn: getAllBooking,
    staleTime: 1000 * 60 * 15, // 15 minutes
  });

  const storageQuery = useQuery({
    queryKey: ["storage", "all"],
    queryFn: getAllStorage,
    staleTime: 1000 * 60 * 15, // 15 minutes
  });

  const statisticsQuery = useQuery({
    queryKey: ["statistics"],
    queryFn: getStatistics,
  });

  const bookingData = bookingQuery.data?.data?.success.data.slice(-5);
  const storageData = storageQuery.data?.data?.success.data.slice(-5);
  const statisticsData = statisticsQuery.data?.data?.success;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const dropdownItemsForBooking = [
    {
      label: "View More",
      key: "viewMore",
      onClick: () => {
        setActiveTabKey("2");
      },
    },
  ];
  // const showModal = () => {
  //   setIsModalOpen(true);
  //
  // };

  const dropdownItemsForStore = [
    {
      label: "View More",
      key: "viewMore",
      onClick: () => {
        setActiveTabKey("3");
      },
    },
  ];

  return (
    <div className="admin-dashboard-container">
      <UserMenu userRole={"admin"} />
      <div className="dashboard-container">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Card className="card-item">
              <div className="card-header">Users</div>
              <div className="card-content">
                <Row justify="space-between" align="middle">
                  <Col>
                    <Statistic
                      title="New Users"
                      value={statisticsData?.user.current}
                      style={{ fontSize: "32px", fontWeight: 600 }}
                    />
                  </Col>
                  <Col>
                    <div className="change">
                      {/* Display the change in percentage */}
                      <span
                        style={{
                          color:
                            statisticsData?.user.change > 0 ? "green" : "red",
                        }}
                      >
                        <Icon
                          type={
                            statisticsData?.user.change > 0
                              ? "arrow-up"
                              : "arrow-down"
                          }
                          style={{ marginRight: 5 }}
                        />
                        {statisticsData?.user.change}%
                      </span>
                      <br />
                      {/* Display the last week's value */}
                      <span style={{ fontSize: "14px", color: "#888" }}>
                        Last Month: {statisticsData?.user.previous} Users
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>

          {/* Card 2 */}
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Card className="card-item">
              <div className="card-header">Sales</div>
              <div className="card-content">
                <Row justify="space-between" align="middle">
                  <Col>
                    <Statistic
                      title="New Transactions"
                      value={statisticsData?.sales.current}
                      prefix="£"
                      style={{ fontSize: "32px", fontWeight: 600 }}
                    />
                  </Col>
                  <Col>
                    <div className="change">
                      <span
                        style={{
                          color:
                            statisticsData?.sales.change > 0 ? "green" : "red",
                        }}
                      >
                        <Icon
                          type={
                            statisticsData?.sales.change > 0
                              ? "arrow-up"
                              : "arrow-down"
                          }
                          style={{ marginRight: 5 }}
                        />
                        {statisticsData?.sales.change}%
                      </span>
                      <br />
                      <span style={{ fontSize: "14px", color: "#888" }}>
                        Last Month: £{statisticsData?.sales.previous}
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>

          {/* Card 3 */}
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Card className="card-item">
              <div className="card-header">Bookings</div>
              <div className="card-content">
                <Row justify="space-between" align="middle">
                  <Col>
                    <Statistic
                      title="New Bookings"
                      value={statisticsData?.bookings.current}
                      style={{ fontSize: "32px", fontWeight: 600 }}
                    />
                  </Col>
                  <Col>
                    <div className="change">
                      <span
                        style={{
                          color:
                            statisticsData?.bookings.change > 0
                              ? "green"
                              : "red",
                        }}
                      >
                        <Icon
                          type={
                            statisticsData?.bookings.change > 0
                              ? "arrow-up"
                              : "arrow-down"
                          }
                          style={{ marginRight: 5 }}
                        />
                        {statisticsData?.bookings.change}%
                      </span>
                      <br />
                      <span style={{ fontSize: "14px", color: "#888" }}>
                        Last Month: {statisticsData?.bookings.previous}
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      <div className="booking-dcontainer">
        <Row>
          <Col span={20}>
            <h2>Recent Bookings Updated</h2>
          </Col>
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
            span={4}
          >
            <div>
              <DropdownOption items={dropdownItemsForBooking} />
              <CreateStore
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
              />
            </div>
          </Col>
          <Col span={24}>
            <div>
              {bookingData?.map((booking, index) => (
                <UserBooking
                  key={index}
                  name={booking.title}
                  status={booking.status}
                  luggageCount={booking.luggage_size}
                  dateOrLocation={moment(booking.drop_off_date).format(
                    "YYYY/MM/DD HH:mm"
                  )}
                />
              ))}
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={20}>
            <h2>New Stores</h2>
          </Col>
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
            span={4}
          >
            <div>
              <DropdownOption
                items={dropdownItemsForStore}
                setIsModalOpen={setIsModalOpen}
              />
            </div>
          </Col>
          <Col span={24}>
            <div>
              {storageData?.map((store, index) => (
                <UserBooking
                  key={index}
                  name={store.title}
                  status={store.status}
                  luggageCount={store.luggage_size}
                  frequency={store?.openingHours}
                  dateOrLocation={moment(store.drop_off_date).format(
                    "YYYY/MM/DD HH:mm"
                  )}
                />
              ))}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AdminDashboard;

/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useQuery } from "@tanstack/react-query";
import { Button, Modal, Skeleton, Space, Tag } from "antd";
import React, { useState } from "react";
import { getAllStorage } from "../../../common/api/storage";
import TableAntd from "../../../common/components/table/TableAntd";
import SearchComponent from "../../../common/search/Search";
import SingleStoreModal from "./SingleStoreModal";
import "./Stores.css";
import UpdateStoreModal from "./UpdateStoreModal";

import { storageStatusArray } from "../../../common/constant/Constants";
import UserMenu from "../../../common/components/menu/UserMenu";

const Stores = () => {
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const [selectedStorageData, setSelectedStorageData] = useState(null);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);

  const storageQuery = useQuery({
    queryKey: ["storage", "all", params],
    queryFn: () => getAllStorage(params),
    staleTime: 1000 * 60 * 15, // 15 minutes
  });

  const storageData = storageQuery.data?.data?.success.data;
  const storageMeta = storageQuery.data?.data?.success.meta;

  const viewStorageData = (data) => {
    setSelectedStorageData(data);
    setIsModalVisible(true);
  };
  const viewUpdateStorageData = (data) => {
    setSelectedStorageData(data);
    setIsUpdateModalVisible(true);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Store Name",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (_, { address }) => {
        return (
          <>
            {address?.address1 +
              ", " +
              address?.address2 +
              ", " +
              address?.postalCode +
              ", " +
              address?.country}
          </>
        );
      },
    },
    {
      title: "Storage Capacity",
      dataIndex: "capacity",
      key: "capacity",
    },
    {
      title: "Hourly Rate",
      dataIndex: "pricing",
      key: "pricing",
      render: (_, { pricing }) => {
        return <>£{pricing?.hourlyRate}</>;
      },
    },
    {
      title: "Daily Rate",
      dataIndex: "pricing",
      key: "pricing",
      render: (_, { pricing }) => {
        return <>£{pricing?.dailyRate}</>;
      },
    },

    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (_, { status }) => (
        <Tag
          color={
            status === "approved"
              ? "green"
              : status === "pending"
              ? "yellow"
              : "volcano"
          }
          key={status}
        >
          {status.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, data) => (
        <Space size="middle">
          <Button
            style={{ cursor: "pointer" }}
            onClick={() => viewStorageData(data)}
          >
            View
          </Button>
          <Button
            sstyle={{ cursor: "pointer" }}
            onClick={() => viewUpdateStorageData(data)}
          >
            Update
          </Button>
        </Space>
      ),
    },
  ];

  // const data = generateMockData();

  const handleTableChange = (pagination) => {
    setParams({
      ...params,
      page: pagination.current,
      limit: pagination.pageSize,
    });
  };

  const searchFilters = [
    { key: "title", label: "Store Name", type: "input" },
    {
      key: "status",
      label: "Status",
      type: "select",
      optionArray: storageStatusArray,
    },
  ];

  const handleSearch = async (filterCriteria) => {
    setParams({ ...params, page: 1, ...filterCriteria });
  };

  return (
    <div>
      <UserMenu userRole={"admin"} />
      <div className="admin-store-container">
        <div>
          <SearchComponent filters={searchFilters} onSearch={handleSearch} />
        </div>
        <div className="booking-table">
          {storageQuery.isLoading ? (
            <Skeleton />
          ) : (
            <TableAntd
              columns={columns}
              data={storageData}
              total={storageMeta?.totalCount}
              handleTableChange={handleTableChange}
              current={params?.page}
              pageSize={params?.limit}
            />
          )}
        </div>
      </div>

      <div className="admin-store-modal-container">
        <Modal
          className="admin-store-modal"
          wrapClassName="admin-store-model"
          // loading={loading}
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          <SingleStoreModal
            storeInfo={selectedStorageData}
            setIsModalVisible={setIsModalVisible}
          />
        </Modal>
      </div>

      <UpdateStoreModal
        storeInfo={selectedStorageData}
        setIsModalVisible={setIsUpdateModalVisible}
        isModalVisible={isUpdateModalVisible}
      />
    </div>
  );
};

export default Stores;

import { API_URLS } from "../configs/api.urls";
import { axiosFile } from "../configs/axios";

export const uploadFile = async (file) => {
  const formData = new FormData();
  formData.append("image", file); // Ensure the backend expects the key "file"

  return await axiosFile({
    url: API_URLS.UPLOAD_FILE_URL, // Replace with your upload API endpoint
    method: "POST",
    data: formData,
  });
};

export const uploadMultipleFile = async (files) => {
  const formData = new FormData();
  // Append each file to the form data under the key "images"
  files.forEach((file) => {
    formData.append("images", file.originFileObj);
  });

  return await axiosFile({
    url: API_URLS.UPLOAD_MULTIPLE_FILE_URL, // Replace with your upload API endpoint
    method: "POST",
    data: formData,
  });
};

export const updateMultipleFile = async (files, deleteFiles) => {
  const formData = new FormData();
  // Append each file to the form data under the key "images"
  files.forEach((file) => {
    formData.append("images", file.originFileObj);
  });

  formData.append("oldPublicIds", deleteFiles);

  return await axiosFile({
    url: API_URLS.UPDATE_MULTIPLE_FILE_URL, // Replace with your upload API endpoint
    method: "POST",
    data: formData,
  });
};

/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useState} from "react";
import {Button, Grid, Modal, Skeleton, Table, Tag} from "antd";
import "./CreatePayoutModal.css";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {getBookingTransactionsByUserId, makePayout,} from "../../../../common/api/transaction";
import {AntNotification} from "../../../../common/components/notification/notification";

const {useBreakpoint} = Grid;

const CreatePayoutModal = ({transaction, visible, onClose}) => {
  const {user_id} = transaction;
  const screens = useBreakpoint();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]); // Selected row keys
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });

  const payoutQuery = useQuery({
    queryKey: ["payout", "user", user_id],
    queryFn: () => getBookingTransactionsByUserId(user_id),
    staleTime: 1000 * 60 * 15, // 15 minutes
  });

  const data = payoutQuery.data?.data?.success.data.filter(
    (item) => item.payout_status === "pending"
  );

  const queryClient = useQueryClient();
  const CreatePayMutation = useMutation({
    mutationFn: makePayout,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["payout"], {exact: true});
      AntNotification("success", "Payout Completed", data.data.success.message)
      onClose();
    },
    onError: (error) => {
      console.log(error.response.data.error);
      AntNotification("error", "Payout Unsuccessful", error.response.data.error.message)
    },
  });

  const handlePay = () => {
    CreatePayMutation.mutate({userId: user_id, bookingIds: selectedRowKeys});
  };

  const columns = [
    {
      title: "User Name",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "Store Name",
      dataIndex: "storage_name",
      key: "storage_name",
    },
    {
      title: "Booking Id",
      dataIndex: "booking_id",
      key: "booking_id",
    },
    {
      title: "Payout Amount",
      dataIndex: "storage_charge",
      key: "storage_charge",
    },
    {
      title: "Payout Status",
      dataIndex: "payout_status",
      key: "payout_status",
      render: (_, {payout_status}) => (
        <Tag
          color={
            payout_status === "pending"
              ? "yellow"
              : payout_status === "succeeded"
                ? "green"
                : "volcano"
          }
          key={payout_status}
        >
          {payout_status.toUpperCase()}
        </Tag>
      ),
    },
  ];

  const handleTableChange = (pagination) => {
    setParams({
      page: pagination.current,
      limit: pagination.pageSize,
    });
  };

  // Row selection configuration
  const rowSelection = {
    selectedRowKeys,
    onChange: (keys) => {
      setSelectedRowKeys(keys); // Update selected row keys
    },
  };

  return (
    <Modal
      title={`Payout Details `}
      open={visible}
      onCancel={onClose}
      footer={[
        <Button disabled={CreatePayMutation.isPending} key="pay" type="primary" onClick={handlePay}>
          Pay
        </Button>,
      ]}
      width={800}
    >
      {payoutQuery.isLoading ? (
        <Skeleton/>
      ) : (
        <div>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
            onChange={handleTableChange}
            scroll={{x: screens.xs ? 400 : null}}
            pagination={{
              size: data?.length,
              responsive: true,
              showSizeChanger: true,
              totalBoundaryShowSizeChanger: data?.length,
              current: params?.page,
              pageSize: params?.limit,
            }}
            rowKey={(record) => record.booking_id}
          />
        </div>
      )}
    </Modal>
  );
};
export default CreatePayoutModal;

/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useQuery } from "@tanstack/react-query";
import { Button, Modal, Skeleton, Space, Tag } from "antd";
import moment from "moment/moment";
import React, { useState } from "react";
import { getAllBooking } from "../../../common/api/booking";
import TableAntd from "../../../common/components/table/TableAntd";
import { bookingStatusArray } from "../../../common/constant/Constants";
import SearchComponent from "../../../common/search/Search";
import SingleBookingModal from "./SingleBookingModal";
import UserMenu from "../../../common/components/menu/UserMenu";

const AdminBooking = () => {
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });

  const [selectedStorageData, setSelectedStorageData] = useState(null);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const viewStorageData = (data) => {
    setSelectedStorageData(data);
    setIsModalVisible(true);
  };

  const bookingQuery = useQuery({
    queryKey: ["bookings", "all", params],
    queryFn: () => getAllBooking(params),
    staleTime: 1000 * 60 * 15, // 15 minutes
  });

  const bookingData = bookingQuery.data?.data?.success.data;
  const bookingMeta = bookingQuery.data?.data?.success.meta;

  const columns = [
    {
      title: "ID",
      dataIndex: "booking_id",
      key: "booking_id",
    },
    {
      title: "Store Name",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Drop Off",
      dataIndex: "drop_off_date",
      key: "drop_off_date",
      render: (_, { drop_off_date }) => {
        return moment(drop_off_date).format("YYYY/MM/DD");
      },
    },
    {
      title: "Pick Up",
      dataIndex: "pick_up_date",
      key: "pick_up_date",
      render: (_, { pick_up_date }) => {
        return moment(pick_up_date).format("YYYY/MM/DD");
      },
    },
    {
      title: "Luggage count",
      dataIndex: "luggage_size",
      key: "luggage_size",
    },
    {
      title: "Amount",
      dataIndex: "total_charge",
      key: "total_charge",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (_, { status }) => (
        <Tag
          color={
            status === "pending"
              ? "yellow"
              : status === "completed"
              ? "green"
              : "volcano"
          }
          key={status}
        >
          {status.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: "Payment Status",
      key: "payment_status",
      dataIndex: "payment_status",
      render: (_, { payment_status }) => (
        <Tag
          color={
            payment_status === "paid"
              ? "green"
              : payment_status === "pending"
              ? "yellow"
              : "volcano"
          }
          key={payment_status}
        >
          {payment_status.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, data) => (
        <Space size="middle">
          <Button
            style={{ cursor: "pointer" }}
            onClick={() => viewStorageData(data)}
          >
            View
          </Button>
        </Space>
      ),
    },
  ];

  const handleTableChange = (pagination) => {
    setParams({
      ...params,
      page: pagination.current,
      limit: pagination.pageSize,
    });
  };

  const searchFilters = [
    { key: "title", label: "Store Name", type: "input" },
    {
      key: "status",
      label: "Booking Status",
      type: "select",
      optionArray: bookingStatusArray,
    },
  ];

  const handleSearch = async (filterCriteria) => {
    setParams({ ...params, page: 1, ...filterCriteria });
  };

  return (
    <div>
      <UserMenu userRole={"admin"} />
      <div className="booking-container">
        <div>
          <SearchComponent filters={searchFilters} onSearch={handleSearch} />
        </div>
        <div className="booking-table">
          {bookingQuery.isLoading ? (
            <Skeleton />
          ) : (
            <TableAntd
              columns={columns}
              data={bookingData}
              total={bookingMeta?.totalCount}
              handleTableChange={handleTableChange}
              current={params?.page}
              pageSize={params?.limit}
            />
          )}
        </div>
      </div>

      <div className="admin-store-modal-container">
        <Modal
          className="admin-store-modal"
          wrapClassName="admin-store-model"
          // loading={loading}
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          <SingleBookingModal
            storeInfo={selectedStorageData}
            setIsModalVisible={setIsModalVisible}
          />
        </Modal>
      </div>
    </div>
  );
};

export default AdminBooking;

/*
 * Copyright © 2023-2025 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useQuery } from "@tanstack/react-query";
import { Skeleton, Typography } from "antd";
import moment from "moment/moment";
import React from "react";
import { getAllServiceCharge } from "../../../common/api/prices";
import TableAntd from "../../../common/components/table/TableAntd";
import PriceForm from "./PriceForm";
import UserMenu from "../../../common/components/menu/UserMenu";

const { Title } = Typography;

const StorePricing = () => {
  const pricesQuery = useQuery({
    queryKey: ["prices"],
    queryFn: getAllServiceCharge,
    staleTime: 1000 * 60 * 15, // 15 minutes
  });

  const prices = pricesQuery.data?.data?.success.data;

  const storePriceColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Total Daily Price",
      dataIndex: "daily_storage_charge",
      key: "daily_storage_charge",
    },
    {
      title: "Total Hourly Price",
      dataIndex: "hourly_storage_charge",
      key: "hourly_storage_charge",
    },
    {
      title: "Storage Daily Price",
      dataIndex: "daily_storage_payout",
      key: "daily_storage_payout",
    },
    {
      title: "Storage Hourly Price",
      dataIndex: "hourly_storage_payout",
      key: "hourly_storage_payout",
    },
    {
      title: "Daily Service Charge ",
      dataIndex: "daily_service_charge",
      key: "daily_service_charge",
    },
    {
      title: "Hourly Service Charge",
      dataIndex: "hourly_service_charge",
      key: "hourly_service_charge",
    },
    {
      title: "Date Effective From",
      dataIndex: "created_at",
      key: "created_at",
      render: (_, { created_at }) => {
        return moment(created_at).format("YYYY/MM/DD");
      },
    },
  ];

  const sortedData = prices?.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );

  return (
    <div>
      <UserMenu userRole={"admin"} />
      <div>
        <div className="booking-container">
          {pricesQuery.isLoading ? (
            <Skeleton />
          ) : (
            <div
              style={{
                maxWidth: "100vw",
                margin: "0 auto",
                padding: "5px",
                borderRadius: "12px",
              }}
            >
              <div className="title-rating">
                {/* Header */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <Title level={3}>Store Pricing</Title>
                  </div>
                </div>

                {/* Ratings */}
              </div>

              {prices && <PriceForm prices={prices[0]} />}
            </div>
          )}

          <TableAntd columns={storePriceColumns} data={sortedData} />
        </div>
      </div>
    </div>
  );
};

export default StorePricing;

/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import "./UserBooking.css";
import { Col, Row, Tag } from "antd"; // Add CSS file for styling
import moment from "moment";

const UserBooking = ({
  name,
  status = "",
  luggageCount,
  frequency = "",
  dateOrLocation,
}) => {
  const xs = "100%";
  const sm = "25%";
  const md = "25%";
  const lg = "25%";
  const xl = "25%";

  const statusColor = {
    open: "#66c13f",
    closed: "#ff4d4f",
    approved: "#66c13f",
    pending: "#d8b733",
    daily: "#d8b733",
    hourly: "#336dd8",
  };

  function isStoreOpen(opening_hours) {
    const currentDay = moment().format("dddd"); // Get current day in lowercase (e.g., "monday")

    // Get the opening and closing times for the current day
    const todayHours = opening_hours[currentDay];

    if (!todayHours) {
      // If there's no opening time for today, assume the store is closed
      return false;
    }

    return todayHours.open;
  }

  const isOpen = isStoreOpen(frequency);

  return (
    <div className="booking-card">
      <div className="booking-header">
        <Row gutter={[8, 8]}>
          <Col
            xs={{ flex: xs }}
            sm={{ flex: sm }}
            md={{ flex: md }}
            lg={{ flex: lg }}
            xl={{ flex: xl }}
          >
            <h3 className="booking-name">{name}</h3>
            <span className={`status ${status.toLowerCase()}`}>{status}</span>
          </Col>
          <Col
            xs={{ flex: xs }}
            sm={{ flex: sm }}
            md={{ flex: md }}
            lg={{ flex: lg }}
            xl={{ flex: xl }}
          >
            <span className="user-b-luggage">{luggageCount} Luggage</span>
          </Col>
          <Col
            xs={{ flex: xs }}
            sm={{ flex: sm }}
            md={{ flex: md }}
            lg={{ flex: lg }}
            xl={{ flex: xl }}
          >
            {frequency ? (
              <span
                className={`user-b-frequency ${isOpen ? "open" : "closed"}`}
              >
                <Tag
                  bordered={false}
                  color={statusColor[isOpen ? "open" : "closed"] || "default"}
                >
                  {frequency ? (isOpen ? "Open" : "Closed") : ""}
                </Tag>
              </span>
            ) : null}
          </Col>
          <Col
            xs={{ flex: xs }}
            sm={{ flex: sm }}
            md={{ flex: md }}
            lg={{ flex: lg }}
            xl={{ flex: xl }}
          >
            <span className="user-b-date">{dateOrLocation}</span>
          </Col>
        </Row>
      </div>
      <div className="booking-details"></div>
    </div>
  );
};

export default UserBooking;

/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useQuery } from "@tanstack/react-query";
import { Button, Modal, Skeleton, Space } from "antd";
import moment from "moment/moment";
import React, { useState } from "react";
import { getBookingByUserId } from "../../../common/api/booking";
import TableAntd from "../../../common/components/table/TableAntd";
import { bookingStatusArray } from "../../../common/constant/Constants";
import SearchComponent from "../../../common/search/Search";
import "./Bookings.css";
import SingleBookingModal from "./SingleBookingModal";
import UserMenu from "../../../common/components/menu/UserMenu";

const Booking = () => {
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedBookingData, setSelectedBookingData] = useState(null);

  const bookingQuery = useQuery({
    queryKey: ["bookings", "user", params],
    queryFn: () => getBookingByUserId(params),
    staleTime: 1000 * 60 * 15, // 15 minutes
  });

  const bookingData = bookingQuery.data?.data?.success.data;
  const bookingMeta = bookingQuery.data?.data?.success.meta;

  const columns = [
    {
      title: "ID",
      dataIndex: "booking_id",
      key: "booking_id",
    },
    {
      title: "Store Name",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Drop Off",
      dataIndex: "drop_off_date",
      key: "drop_off_date",
      render: (_, { drop_off_date }) => {
        return moment(drop_off_date).format("YYYY/MM/DD");
      },
    },
    {
      title: "Pick Up",
      dataIndex: "pick_up_date",
      key: "pick_up_date",
      render: (_, { drop_off_date }) => {
        return moment(drop_off_date).format("YYYY/MM/DD");
      },
    },
    {
      title: "Luggage count",
      dataIndex: "luggage_size",
      key: "luggage_size",
    },
    {
      title: "Amount",
      dataIndex: "total_charge",
      key: "total_charge",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      // render: (_, { tags }) => (
      //   <>
      //     {tags.map((tag) => {
      //       let color = tag.length > 9 ? "geekblue" : "green";
      //       if (tag === "canceled") {
      //         color = "volcano";
      //       }
      //       return (
      //         <Tag color={color} key={tag}>
      //           {tag.toUpperCase()}
      //         </Tag>
      //       );
      //     })}
      //   </>
      // ),
    },
    {
      title: "Payment Status",
      key: "payment_status",
      dataIndex: "payment_status",
      // render: (_, { tags }) => (
      //   <>
      //     {tags.map((tag) => {
      //       let color = tag.length > 9 ? "geekblue" : "green";
      //       if (tag === "canceled") {
      //         color = "volcano";
      //       }
      //       return (
      //         <Tag color={color} key={tag}>
      //           {tag.toUpperCase()}
      //         </Tag>
      //       );
      //     })}
      //   </>
      // ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => handleViewModal(record)}>View</Button>
        </Space>
      ),
    },
  ];

  const handleViewModal = (data) => {
    setSelectedBookingData(data);
    setIsModalVisible(true);
  };

  const handleTableChange = (pagination) => {
    setParams({
      ...params,
      page: pagination.current,
      limit: pagination.pageSize,
    });
  };

  const searchFilters = [
    { key: "title", label: "Store Name", type: "input" },
    {
      key: "status",
      label: "Booking Status",
      type: "select",
      optionArray: bookingStatusArray,
    },
  ];

  const handleSearch = async (filterCriteria) => {
    setParams({ ...params, paage: 1, ...filterCriteria });
  };

  return (
    <div>
      <UserMenu userRole={"user"} />
      <div className="booking-container">
        <div>
          <SearchComponent filters={searchFilters} onSearch={handleSearch} />
        </div>
        <div className="booking-table">
          {bookingQuery.isLoading ? (
            <Skeleton />
          ) : (
            <TableAntd
              columns={columns}
              data={bookingData}
              total={bookingMeta?.totalCount}
              handleTableChange={handleTableChange}
              isLoading={bookingQuery.isLoading}
              current={params?.page}
              pageSize={params?.limit}
            />
          )}
        </div>
      </div>
      <div className="single-booking-modal-container">
        <Modal
          className="single-booking-modal"
          wrapClassName="single-booking-modal"
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          <SingleBookingModal data={selectedBookingData} />
        </Modal>
      </div>
    </div>
  );
};

export default Booking;

/*
 * Copyright © 2023-2025 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useEffect, useState} from 'react';
import {Menu} from "antd";
import {adminMenuItems, partnerMenuItems, userMenuItems} from "../../constant/Constants";
import {useLocation} from "react-router-dom";

const UserMenu = ({userRole}) => {

  const [currentKey, setCurrentKey] = useState("");
  const location = useLocation();

  // const userRole = "user";

  // Select menu items based on the user's role
  const menuItems = userRole === "user" ? userMenuItems : userRole === "partner" ?
    partnerMenuItems: adminMenuItems;


  useEffect(() => {
    const activeItem = menuItems.find((item) => item.label.props.to === location.pathname);
    if (activeItem) setCurrentKey(activeItem.key);
  }, [location,menuItems]);

  // Handle menu item clicks
  const handleMenuClick = (e) => {
    setCurrentKey(e.key);
  };
  return (
    <div>
      <Menu
        mode="horizontal" // or "horizontal"
        selectedKeys={[currentKey]}
        onClick={handleMenuClick}
        items={menuItems}
      />
    </div>
  );
};

export default UserMenu;
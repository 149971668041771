/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Skeleton,
  Typography,
  Upload,
} from "antd";
import "./MyAccount.css";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  deleteCurrentUser,
  getCurrentUser,
  updateCurrentUser,
  updateCurrentUserPassword,
} from "../../api/user";
import {
  CreateBankAccount,
  getBankAccountsByUserId,
  UpdateBankAccount,
} from "../../api/account";
import { uploadFile } from "../../api/file";
import { UploadOutlined } from "@ant-design/icons";
import UseAuth from "../../hooks/UseAuth";
import { getStorageByUserId } from "../../api/storage";
import { Option } from "antd/es/mentions";
import { AntNotification } from "../notification/notification";

const { Title, Text } = Typography;

const europeanPaymentFields = {
  GB: {
    // United Kingdom (England, Scotland, Wales, Northern Ireland)
    accountNumber: true,
    routingNumber: false,
    sortCode: true,
    iban: false,
  },
  DE: {
    // Germany
    accountNumber: true,
    routingNumber: false,
    iban: true,
    sortCode: false,
  },
  FR: {
    // France
    accountNumber: true,
    routingNumber: false,
    iban: true,
    sortCode: false,
  },
  ES: {
    // Spain
    accountNumber: true,
    routingNumber: false,
    iban: true,
    sortCode: false,
  },
  IT: {
    // Italy
    accountNumber: true,
    routingNumber: false,
    iban: true,
    sortCode: false,
  },
  NL: {
    // Netherlands
    accountNumber: true,
    routingNumber: false,
    iban: true,
    sortCode: false,
  },
  // Add more European countries as needed
};

const MyAccount = () => {
  const { auth } = UseAuth();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [formType, setFormType] = useState(null);
  const [upload, setUpload] = useState(null);

  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const handleCountryChange = (value) => {
    setCountry(value);
  };

  const currentUserQuery = useQuery({
    queryKey: ["currentUser"],
    queryFn: getCurrentUser,
    staleTime: 1000 * 60 * 15, // 15 minutes
  });

  const bankInfoQuery = useQuery({
    queryKey: ["bankInfo"],
    queryFn: getBankAccountsByUserId,
    staleTime: 1000 * 60 * 15, // 15 minutes
  });

  const storageQuery = useQuery({
    queryKey: ["storage"],
    queryFn: getStorageByUserId,
    staleTime: 1000 * 60 * 15, // 15 minutes
    enabled: auth?.userType === "user",
  });

  const storageData = storageQuery.data?.data?.success.data.length;

  const updateCurrentUserMutation = useMutation({
    mutationFn: updateCurrentUser,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["currentUser"], { exact: true });
      message.success(data.data.success.message);
      setIsModalVisible(false);
      setFormType(null);
      form.resetFields();
    },
    onError: (data) => {
      const fieldsErrors = Object.keys(data.response.data.error).map(
        (field) => ({
          name: field, // Field name in the form
          errors: [data.response.data.error[field]], // Error message as an array
        })
      );

      // Set the errors in the form
      return form.setFields(fieldsErrors);
    },
  });

  const deleteCurrentUserMutation = useMutation({
    mutationFn: deleteCurrentUser,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["currentUser"], { exact: true });
      message.success(data.data.success.message);
      setIsModalVisible(false);
      setFormType(null);
      form.resetFields();
    },
    onError: (error) => {
      AntNotification(
        "error",
        "Invalid Data",
        error.response.data.error.message
      );
    },
  });

  const CreateBankAccountMutation = useMutation({
    mutationFn: CreateBankAccount,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["bankInfo"], { exact: true });
      message.success(data.data.success.message);
      setIsModalVisible(false);
      setFormType(null);
      form.resetFields();
    },
    onError: (error) => {
      console.log(error);
      AntNotification(
        "error",
        "Invalid Data",
        error.response.data.error.message
      );
    },
  });

  const UpdateBankAccountMutation = useMutation({
    mutationFn: ({ id, data }) => UpdateBankAccount(id, data),
    onSuccess: (data) => {
      queryClient.invalidateQueries(["bankInfo"], { exact: true });
      message.success(data.data.success.message);
      setIsModalVisible(false);
      setFormType(null);
      form.resetFields();
      setUpload(null);
    },
    onError: (error) => {
      AntNotification(
        "error",
        "Invalid Data",
        error.response.data.error.message
      );
    },
  });

  const updateCurrentUserPasswordMutation = useMutation({
    mutationFn: updateCurrentUserPassword,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["currentUserPassword"], { exact: true });
      message.success(data.data.success.message);
      setIsModalVisible(false);
      setFormType(null);
      form.resetFields();
    },
    onError: (error) => {
      AntNotification(
        "error",
        "Invalid Data",
        error.response.data.error.message
      );
    },
  });

  const data = currentUserQuery.data?.data?.success.data;
  const bankInfoData = bankInfoQuery.data?.data?.success.data;

  const [country, setCountry] = useState(bankInfoData?.country || "GB");
  const fields = europeanPaymentFields[country];

  const handleOpenModal = (type, formType) => {
    setModalType(type);
    setFormType(formType);
    setIsModalVisible(true);
    if (type === "userInfo") {
      form.setFieldsValue(data || {});
    } else if (type === "bankInfo") {
      form.setFieldsValue(bankInfoData || {});
    }
  };

  const handleOk = () => {
    form.validateFields().then(async (values) => {
      if (modalType === "userInfo") {
        updateCurrentUserMutation.mutate({
          fullName: values.full_name,
          phoneNumber: values.phone_number,
        });
      } else if (modalType === "bankInfo" && formType === "create") {
        if (file === null) {
          AntNotification(
            "error",
            "Invalid Data",
            "Please select a file to upload"
          );
          return;
        }

        const response = await uploadFile(file);
        if (response.data.status === false) {
          AntNotification("error", "Invalid Data", response.data.error.message);
          return;
        }

        CreateBankAccountMutation.mutate({
          firstName: values.first_name,
          lastName: values.last_name,
          phone: values.phone_number,
          dob: values.dob,
          country: country,
          bankName: values.bank_name,
          accountNumber: values.account_number,
          routingNumber: values.routing_number,
          verificationFileLink: response.data.success.data.cloudinaryUrl,
          verificationFileId: response.data.success.data.stripeFileId,
        });
      } else if (modalType === "bankInfo" && formType === "edit") {
        if (file === null) {
          AntNotification("error", "Please select a file to upload");
          return;
        }

        let response;

        if (!upload) {
          response = await uploadFile(file);
          if (response.data.status === false) {
            AntNotification(
              "error",
              "Invalid Data",
              response.data.error.message
            );
            return;
          }

          setUpload(response.data.success.data);
        }

        UpdateBankAccountMutation.mutate({
          id: bankInfoData.id,
          data: {
            firstName: values.first_name,
            lastName: values.last_name,
            phone: values.phone_number,
            dob: values.dob,
            country: country,
            bankName: values.bank_name,
            accountNumber: values.account_number,
            routingNumber: values.routing_number,
            verificationFileLink: upload
              ? upload.cloudinaryUrl
              : response.data.success.data.cloudinaryUrl,
            verificationFileId: upload
              ? upload.stripeFileId
              : response.data.success.data.stripeFileId,
          },
        });
      } else if (modalType === "security") {
        updateCurrentUserPasswordMutation.mutate(values);
      }
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDelete = () => {
    deleteCurrentUserMutation.mutate();
  };

  const [file, setFile] = useState(null);

  const props = {
    beforeUpload: (file) => {
      const isPNG = file.type === "image/png";
      const isLessThan1MB = file.size <= 1024 * 1024; // 1MB

      if (!isPNG) {
        AntNotification(
          "error",
          "Invalid Data",
          `${file.name} is not a PNG file`
        );
      }

      if (!isLessThan1MB) {
        AntNotification(
          "error",
          "Invalid Data",
          `${file.name} exceeds the 1MB size limit`
        );
      }

      return isPNG && isLessThan1MB ? true : Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      if (info.file.status === "removed") {
        setFile(null); // Handle file removal
      } else {
        setFile(info.file.originFileObj); // Save file to state
      }
    },
  };

  return (
    <div>
      <div className="my-account" style={{ padding: "10px", margin: "0 auto" }}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Card bordered={false}>
              {currentUserQuery.isLoading ? (
                <Skeleton />
              ) : currentUserQuery.isError ? (
                <div>
                  Error: {currentUserQuery.error.response.data.error.message}
                </div>
              ) : (
                <>
                  <Title level={5}>User Information</Title>
                  <Text>
                    Make sure your contact information is accurate, so we can
                    reach you regarding your bookings
                  </Text>
                  <div style={{ marginTop: "16px" }}>
                    <Text strong>Name:</Text> <Text>{data?.full_name}</Text>
                    <br />
                    <Text strong>Email:</Text>
                    <Text>{data?.email_address}</Text>
                    <br />
                    <Text strong>Mobile Number:</Text>
                    <Text>{data?.phone_number}</Text>
                  </div>
                  <Button
                    type="default"
                    style={{ marginTop: "16px" }}
                    onClick={() => handleOpenModal("userInfo", "edit")}
                  >
                    Edit
                  </Button>
                </>
              )}
            </Card>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12}>
            <Card bordered={false} style={{ borderRadius: "8px" }}>
              <Title level={5}>Security</Title>
              <Text>
                Keep your password strong to prevent unauthorized access to your
                account
              </Text>
              <div style={{ marginTop: "16px" }}>
                <Text strong>Password:</Text> <Text>******</Text>
              </div>
              <Button
                type="default"
                style={{ marginTop: "16px" }}
                onClick={() => handleOpenModal("security", "edit")}
              >
                Edit
              </Button>
            </Card>
          </Col>

          <Col order={2} xs={24} sm={24} md={12} lg={12}>
            <Card bordered={false} style={{ borderRadius: "8px" }}>
              <Title level={5}>Delete Account</Title>
              <div>
                <Text>
                  Deleting your account will also remove all your personal
                  information from our database
                </Text>
              </div>
              <Popconfirm
                title="Delete My Account"
                description="Are you sure to delete?"
                onConfirm={handleDelete}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary" danger style={{ marginTop: "16px" }}>
                  Delete My Account
                </Button>
              </Popconfirm>
            </Card>
          </Col>
          {auth.userType === "user" && storageData > 0 ? (
            <Col order={1} xs={24} sm={24} md={12} lg={12}>
              <Card bordered={false} style={{ borderRadius: "8px" }}>
                <Title level={5}>Bank Details</Title>
                {bankInfoData ? (
                  <>
                    <Text>
                      Make sure your bank information is accurate, so we can
                      perform transactions of your earnings
                    </Text>
                    <div style={{ marginTop: "16px" }}>
                      <Text strong>Bank Name:</Text>
                      <Text>{bankInfoData.bank_name}</Text>
                      <br />
                      <Text strong>Account Number:</Text>
                      <Text>{bankInfoData.account_number}</Text>
                      <br />
                      <Text strong>Account Holder Name:</Text>
                      <Text>{bankInfoData.account_holder_name}</Text>
                      <br />
                      <Text strong>Swift Code:</Text>
                      <Text>{bankInfoData.routing_number}</Text>
                    </div>
                    <Button
                      type="default"
                      style={{ marginTop: "16px" }}
                      onClick={() => handleOpenModal("bankInfo", "edit")}
                    >
                      Edit
                    </Button>
                  </>
                ) : (
                  <>
                    <div>
                      <Text>
                        No bank details provided. Please add your bank
                        information.
                      </Text>
                    </div>
                    <Button
                      type="primary"
                      style={{ marginTop: "16px" }}
                      onClick={() => handleOpenModal("bankInfo", "create")}
                    >
                      Add Bank Details
                    </Button>
                  </>
                )}
              </Card>
            </Col>
          ) : (
            <Col order={1} xs={24} sm={24} md={12} lg={12}></Col>
          )}
        </Row>
      </div>

      <Modal
        title={
          modalType === "userInfo"
            ? "Edit User Information"
            : modalType === "security"
            ? "Change Password"
            : modalType === "bankInfo" && formType === "create"
            ? "Add Bank Details"
            : "Edit Bank Details"
        }
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form} layout="vertical">
          {modalType === "userInfo" && (
            <>
              <Form.Item
                label="Name"
                name="full_name"
                rules={[{ required: true, message: "Please enter your name" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item label="Email" name="email_address">
                <Input disabled />
              </Form.Item>
              <Form.Item
                label="Mobile Number"
                name="phone_number"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                  {
                    pattern: /[+][0-9]{10}[0-9]{0,5}$/,
                    message:
                      "Please enter a valid mobile number(Ex: +911234567890).",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </>
          )}
          {modalType === "security" && (
            <>
              <Form.Item
                label="Current Password"
                name="oldPassword"
                rules={[
                  {
                    required: true,
                    message: "Please enter your current password",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label="New Password"
                name="newPassword"
                rules={[
                  { required: true, message: "Please enter a new password" },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label="Confirm New Password"
                name="confirmNewPassword"
                dependencies={["newPassword"]}
                rules={[
                  {
                    required: true,
                    message: "Please confirm your new password",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("newPassword") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("The two passwords do not match")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            </>
          )}
          {modalType === "bankInfo" && (
            <>
              <Form.Item
                label="Account Holder First Name"
                name="first_name"
                rules={[
                  {
                    required: true,
                    message: "Please enter your account Holder First Name",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Account Holder Name"
                name="last_name"
                rules={[
                  {
                    required: true,
                    message: "Please enter your account Holder Last Name",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Account Holder Phone Number"
                name="phone_number"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                  {
                    pattern: /[+][0-9]{10}[0-9]{0,5}$/,
                    message:
                      "Please enter a valid mobile number(Ex: +911234567890).",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Account Holder DoB"
                name="dob"
                rules={[
                  {
                    required: true,
                    message: "Please enter your DOB",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.reject("Please enter your DOB");
                      }
                      const today = new Date();
                      const dob = new Date(value);
                      const age = today.getFullYear() - dob.getFullYear();
                      if (age >= 18) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "You must be at least 18 years old"
                      );
                    },
                  }),
                ]}
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                label="Account Holder Verification Document"
                name="vdoc"
              >
                <Upload {...props}>
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              </Form.Item>
              <Form.Item label="Account Holder Bank Country" name="country">
                <Select value={country} onChange={handleCountryChange}>
                  <Option value="GB">United Kingdom</Option>
                  <Option value="DE">Germany</Option>
                  <Option value="FR">France</Option>
                  <Option value="ES">Spain</Option>
                  <Option value="IT">Italy</Option>
                  <Option value="NL">Netherlands</Option>
                  {/* Add more European countries as needed */}
                </Select>
              </Form.Item>
              <Form.Item
                label="Bank Name"
                name="bank_name"
                rules={[
                  { required: true, message: "Please enter your bank name" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Account Number"
                name="account_number"
                rules={[
                  {
                    required: true,
                    message: "Please enter your account number",
                  },
                  {
                    pattern: /^\d{6,18}$/, // Ensures only digits with a length of 6 to 18
                    message: "Account number must be between 6 and 18 digits",
                  },
                ]}
              >
                <Input placeholder="Enter your account number (ex: 000123456789)" />
              </Form.Item>
              {fields.routingNumber && (
                <Form.Item
                  label="Bank Routing Number"
                  name="routing_number"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Bank Routing Number",
                    },
                    {
                      pattern: /^\d{9}$/, // Ensures exactly 9 digits
                      message: "Routing number must be exactly 9 digits",
                    },
                  ]}
                >
                  <Input placeholder="Enter your bank routing number (ex: 110000000)" />
                </Form.Item>
              )}

              {fields.sortCode && (
                <Form.Item
                  label="Sort Code"
                  name="routing_number"
                  rules={[
                    {
                      required: true,
                      message: "Please enter sortCode",
                    },
                    {
                      pattern: /^\d{6}$/, // Ensures exactly 6 digits
                      message: "Sort Code must be exactly 6 digits",
                    },
                  ]}
                >
                  <Input placeholder="Enter your bank sortCode (ex: 231486)" />
                </Form.Item>
              )}

              {fields.iban && (
                <Form.Item
                  label="IBAN Number"
                  name="routing_number"
                  rules={[
                    {
                      required: true,
                      message: "Please enter IBAN Number",
                    },
                    {
                      pattern: /^[A-Z]{2}\d{2}[A-Z0-9]{11,30}$/, // IBAN Format
                      message:
                        "Invalid IBAN format. Example: GB82WEST12345698765432",
                    },
                  ]}
                >
                  <Input placeholder="Enter your bank IBAN number (ex: GB82WEST12345698765432)" />
                </Form.Item>
              )}
            </>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default MyAccount;

/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Image, message, Upload } from "antd";
import React, { useState } from "react";
import { updateMultipleFile } from "../../../common/api/file";
import { AntNotification } from "../../../common/components/notification/notification";
import { updateStorageImages } from "../../../common/api/storage";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const EditImages = ({ storageId, data, setEditModal }) => {
  const [form] = Form.useForm();

  const [fileList, setFileList] = useState(data || []);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const queryClient = useQueryClient();

  const getDeletedPublicIds = (oldArray, newArray) => {
    const newPublicIds = new Set(
      newArray.map((item) => item.publicId).filter(Boolean)
    );
    return oldArray
      .map((item) => item.publicId)
      .filter((id) => id && !newPublicIds.has(id));
  };

  const getNewImages = (images) => {
    return images.filter(
      (image) => image.originFileObj && typeof image.originFileObj === "object"
    );
  };

  const getOldImages = (images) => {
    return images.filter(
      (image) => !image.originFileObj || typeof image.originFileObj !== "object"
    );
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleImageChange = ({ fileList: newFileList }) => {
    setFileList(
      newFileList?.map((file) => ({
        ...file,
        originFileObj: file.originFileObj || "",
      }))
    );
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const submitImageUpdateQuery = useMutation({
    mutationFn: ({ id, data }) => updateStorageImages(id, data),
    onSuccess: (data) => {
      message.success(data.data.success.message).then(() => {
        queryClient.invalidateQueries(["storage"], { exact: true });
        setEditModal(false);
      });
    },
    onError: (error) => {
      message.error(error.response.data.error.message);
    },
  });

  const handleSave = async () => {
    const deleteImages = getDeletedPublicIds(data, fileList);
    const uploadImages = getNewImages(fileList);
    const keepImages = getOldImages(fileList);

    const response = await updateMultipleFile(uploadImages, deleteImages);
    if (response.data.status === false) {
      AntNotification("error", "Invalid Data", response.data.error.message);
      return;
    }

    submitImageUpdateQuery.mutate({
      id: storageId,
      data: { images: [...keepImages, ...response.data.success.data] },
    });
  };

  return (
    <div>
      <Form form={form} layout="vertical">
        <Form.Item label="Store Images" name="vdoc">
          <Upload
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleImageChange}
          >
            {fileList.length >= 5 ? null : uploadButton}
          </Upload>
          {previewImage && (
            <Image
              wrapperStyle={{
                display: "none",
              }}
              preview={{
                visible: previewOpen,
                onVisibleChange: (visible) => setPreviewOpen(visible),
                afterOpenChange: (visible) => !visible && setPreviewImage(""),
              }}
              src={previewImage}
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button
            onClick={handleSave}
            htmlType="submit"
            disabled={submitImageUpdateQuery.isPending}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditImages;

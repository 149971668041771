/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import { Typography, Row, Col, Divider, Image } from "antd";

const { Title, Text } = Typography;

const FinalStep = ({ storeInfo, businessHours }) => {
  return (
    <div>
      <div style={{ maxWidth: 800, margin: "0 auto", padding: "20px" }}>
        <Title
          level={3}
          style={{
            textAlign: "center",
            marginBottom: "20px",
            borderBottom: "1px solid #000",
          }}
        >
          Review Information
        </Title>

        <Title level={4}>Store Information</Title>
        <Row style={{ textAlign: "start", lineHeight: "5px" }} gutter={[16, 8]}>
          <Col span={10}>
            <Text strong>Store Name:</Text>
          </Col>
          <Col span={14}>
            <Text>{storeInfo.title}</Text>
          </Col>
          <Col span={10}>
            <Text strong>Store Location:</Text>
          </Col>
          <Col span={14}>
            <Text>
              {[
                storeInfo.address1,
                storeInfo.address2,
                storeInfo.postalCode,
                storeInfo.city,
                storeInfo.country,
              ]
                .filter(Boolean)
                .join(", ")}
            </Text>
          </Col>
          <Col span={10}>
            <Text strong>Description:</Text>
          </Col>
          <Col span={14}>
            <Text>{storeInfo.description}</Text>
          </Col>

          {/*<Col span={10}>*/}
          {/*  <Text strong>Hourly Rate:</Text>*/}
          {/*</Col>*/}
          {/*<Col span={14}>*/}
          {/*  <Text>{`£${storeInfo.hourlyRate}`}</Text>*/}
          {/*</Col>*/}

          {/*<Col span={10}>*/}
          {/*  <Text strong>Daily Rate:</Text>*/}
          {/*</Col>*/}
          {/*<Col span={14}>*/}
          {/*  <Text>{`£${storeInfo.dailyRate}`}</Text>*/}
          {/*</Col>*/}

          <Col span={10}>
            <Text strong>Store Capacity:</Text>
          </Col>
          <Col span={14}>
            <Text>{storeInfo.storeCapacity + " Luggage"}</Text>
          </Col>
        </Row>

        <Divider />

        <Title level={4}>Contact Information</Title>
        <Row style={{ textAlign: "start", lineHeight: "5px" }} gutter={[16, 8]}>
          <Col span={10}>
            <Text strong>Contact Name:</Text>
          </Col>
          <Col span={14}>
            <Text>{storeInfo.contactName}</Text>
          </Col>
          <Col span={10}>
            <Text strong>Email:</Text>
          </Col>
          <Col span={14}>
            <Text>{storeInfo.contactEmail}</Text>
          </Col>
          <Col span={10}>
            <Text strong>Mobile:</Text>
          </Col>
          <Col span={14}>
            <Text>{storeInfo.contactMobile}</Text>
          </Col>
          <Col span={10}>
            <Text strong>Website:</Text>
          </Col>
          <Col span={14}>
            <Text>{storeInfo.contactWebsite}</Text>
          </Col>
        </Row>

        <Divider />

        <Title level={4}>Store Images</Title>

        <Row style={{ textAlign: "start", lineHeight: "5px" }} gutter={[16, 8]}>
          <div
            className="store-images"
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {storeInfo?.images?.length > 0 ? (
              <Image.PreviewGroup>
                {storeInfo?.images.map((image, index) => {
                  return (
                    <div key={index}>
                      <Image
                        style={{ padding: 5 }}
                        width={350}
                        src={image.url}
                      />
                    </div>
                  );
                })}
              </Image.PreviewGroup>
            ) : (
              ""
            )}
            <br />
          </div>
        </Row>

        <Divider />

        <Title level={4}>Business Hours</Title>
        <Row style={{ textAlign: "start", lineHeight: "5px" }} gutter={[16, 8]}>
          <Col span={12}>
            <Text strong>Always Open:</Text>
          </Col>
          <Col span={6}>
            <Text>
              {Object.values(businessHours).some((day) => !day.open)
                ? "No"
                : "Yes"}
            </Text>
          </Col>
        </Row>
        {Object.keys(businessHours).map((day) => (
          <Row
            style={{ textAlign: "start", lineHeight: "10px" }}
            key={day}
            gutter={[16, 8]}
          >
            <Col span={10}>
              <Text strong>{day}:</Text>
            </Col>
            <Col span={14}>
              {businessHours[day].open ? (
                businessHours[day].hours.map((timeSlot, index) => (
                  <div key={index}>
                    <Text>{`${timeSlot.openTime} - ${timeSlot.closeTime}`}</Text>
                  </div>
                ))
              ) : (
                <Text>Closed</Text>
              )}
            </Col>
          </Row>
        ))}
      </div>
    </div>
  );
};

export default FinalStep;

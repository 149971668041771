/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { PlusOutlined } from "@ant-design/icons";
import { Form, Input, InputNumber, Typography, Upload, Image } from "antd";
import React, { useState } from "react";
import "./Step.css";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const SecondStep = ({ fileList, setFileList }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleImageChange = ({ fileList: newFileList }) =>
    // setFileList(newFileList);

    setFileList(
      newFileList?.map((file) => ({
        originFileObj: file.originFileObj,
        preview: URL.createObjectURL(file.originFileObj),
      }))
    );

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  return (
    <div className="second-step-form">
      <Typography.Title level={3}>Store Information</Typography.Title>

      {/* Store Name */}
      <Form.Item
        label="Store Name"
        name="title"
        className="second-step-store-name"
        rules={[{ required: true, message: "Please enter the store name" }]}
      >
        <Input placeholder="Enter your store name" />
      </Form.Item>
      <Form.Item
        label="Store Sub Title"
        name="subTitle"
        className="second-step-store-name"
      >
        <Input placeholder="Enter your store sub title" />
      </Form.Item>

      {/* Business Registration Number */}
      <Form.Item
        label="Business Registration Number / VAT Number"
        name="registrationNumber"
        rules={[
          {
            required: true,
            message: "Please enter Business Registration Number / VAT Number",
          },
        ]}
      >
        <Input placeholder="Enter registration number or VAT number" />
      </Form.Item>

      <Form.Item
        className="step-store-label"
        layout={"horizontal"}
        label={
          <div className="step-store-name-desc">
            <div>
              <Typography.Text strong>Storage Capacity</Typography.Text>
            </div>
            <div>
              <Typography.Text
                type="secondary"
                style={{ display: "inline-block", fontSize: 12 }}
              >
                Storage Space available at your location
              </Typography.Text>
            </div>
          </div>
        }
        name="storeCapacity"
        rules={[{ required: true, message: "Please enter the store capacity" }]}
      >
        <InputNumber min={1} max={100} defaultValue={1} />
      </Form.Item>

      <Form.Item label="Store Images" name="vdoc">
        <Upload
          listType="picture-card"
          fileList={fileList}
          onPreview={handlePreview}
          onChange={handleImageChange}
        >
          {fileList.length >= 5 ? null : uploadButton}
        </Upload>
        {previewImage && (
          <Image
            wrapperStyle={{
              display: "none",
            }}
            preview={{
              visible: previewOpen,
              onVisibleChange: (visible) => setPreviewOpen(visible),
              afterOpenChange: (visible) => !visible && setPreviewImage(""),
            }}
            src={previewImage}
          />
        )}
      </Form.Item>

      {/* Daily Rate */}

      {/*<Form.Item*/}
      {/*  className="step-store-label"*/}
      {/*  layout="horizontal"*/}
      {/*  label={*/}
      {/*    <div className="step-store-name-desc">*/}
      {/*      <div>*/}
      {/*        <Typography.Text strong>Daily Rate</Typography.Text>*/}
      {/*      </div>*/}
      {/*      <div>*/}
      {/*        <Typography.Text*/}
      {/*          type="secondary"*/}
      {/*          style={{ display: "block", fontSize: 12 }}*/}
      {/*        >*/}
      {/*          Daily rate for one storage space*/}
      {/*        </Typography.Text>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  }*/}
      {/*  name="dailyRate"*/}
      {/*  rules={[{ required: true, message: "Please enter the daily rate" }]}*/}
      {/*>*/}
      {/*  <InputNumber min={1} defaultValue={1} placeholder="Rate" />*/}
      {/*</Form.Item>*/}

      {/* Hourly Rate */}

      {/*<Form.Item*/}
      {/*  className="step-store-label"*/}
      {/*  layout="horizontal"*/}
      {/*  label={*/}
      {/*    <div className="step-store-name-desc">*/}
      {/*      <div>*/}
      {/*        <Typography.Text strong>Hourly Rate</Typography.Text>*/}
      {/*      </div>*/}
      {/*      <div>*/}
      {/*        <Typography.Text*/}
      {/*          type="secondary"*/}
      {/*          style={{ display: "block", fontSize: 12 }}*/}
      {/*        >*/}
      {/*          Hourly rate for one storage space*/}
      {/*        </Typography.Text>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  }*/}
      {/*  name="hourlyRate"*/}
      {/*  rules={[{ required: true, message: "Please enter the hourly rate" }]}*/}
      {/*>*/}
      {/*  <InputNumber min={1} defaultValue={1} placeholder="Rate" />*/}
      {/*</Form.Item>*/}

      {/*/!* Store Image *!/*/}
      {/*<Form.Item*/}
      {/*  label="Store Image"*/}
      {/*  name="storeImage"*/}
      {/*>*/}
      {/*  <Upload*/}
      {/*    listType="picture-card"*/}
      {/*    maxCount={3}*/}
      {/*    accept=".jpg,.jpeg,.png"*/}
      {/*  >*/}
      {/*    <div>*/}
      {/*      <PlusOutlined/>*/}
      {/*      <div style={{marginTop: 8}}>New Image</div>*/}
      {/*    </div>*/}
      {/*  </Upload>*/}
      {/*</Form.Item>*/}
      {/*</Form>*/}
    </div>
  );
};

export default SecondStep;

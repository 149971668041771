/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import {Route, Routes} from "react-router-dom";
import Home from "./page/home";
import GlobalLayout from "./page/globalLayout/GlobalLayout";
import RequireAuth from "./page/requireAuth/RequireAuth";
import Unauthorized from "./page/unauthorized/Unauthorized";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import MyAccount from "./common/components/myaccount/MyAccount";
import Book from "./page/booking";
import EmailVerify from "./page/emailVerify/EmailVerify";
import StoreView from "./page/booking/store/StoreView";
import ContactUs from "./page/contactUs/ContactUsForm/ContactUs";
import PaymentStatus from "./page/booking/paymentStatus/PaymentStatus";
import NotFound from "./common/components/not-found/NotFound";
import TermsAndConditions from "./page/termsAndConditions/TermsAndConditions";
import PartnerDashboard from "./page/partner/dashboard/PartnerDashboard";
import PartnerTransaction from "./page/partner/transaction/PartnerTransaction";
import PartnerBusiness from "./page/partner/business/PartnerBusiness";
import Storage from "./page/partner/storage/Storage";
import Booking from "./page/partner/booking/Booking";
import AdminDashboard from "./page/admin/dashboard/AdminDashboard";
import AdminBooking from "./page/admin/booking/AdminBooking";
import Stores from "./page/admin/business/Stores";
import StorePricing from "./page/admin/pricing/StorePricing";
import Users from "./page/admin/user/Users";
import Transaction from "./page/admin/transaction/Transaction";
import Payout from "./page/admin/payout/Payout";

const ROLES = {
  User: "user",
  Admin: "admin",
  Manager: "manager",
};

const Layout = () => {
  return (
    <main className="App">
      <GlobalLayout />
    </main>
  );
};

function App() {
  return (
    <div className="App">
      <Routes>
        {/* public routes */}
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="active/:id/:token" element={<EmailVerify />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="/booking" element={<Book />} />
          <Route path="/store/:id" element={<StoreView />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/return" element={<PaymentStatus />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />

          {/* protected routes */}
          <Route
            element={
              <RequireAuth
                allowedRoles={[ROLES.Admin, ROLES.User, ROLES.Manager]}
              />
            }
          >
            <Route path="account">
              <Route index element={<MyAccount />} />
            </Route>
          </Route>
          <Route
            element={
              <RequireAuth allowedRoles={[ROLES.Admin, ROLES.Manager]} />
            }
          >
            <Route path="admin">
              <Route  path="dashboard" element={<AdminDashboard />} />
              <Route  path="booking" element={<AdminBooking />} />
              <Route  path="storage" element={<Stores />} />
              <Route  path="price" element={<StorePricing />} />
              <Route  path="users" element={<Users />} />
              <Route  path="transaction" element={<Transaction />} />
              <Route  path="payout" element={<Payout />} />
            </Route>
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
            <Route path="user">
              <Route  path="booking" element={<Booking />} />
            </Route>
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
            <Route path="partner">
              <Route path="dashboard" element={<PartnerDashboard />} />
              <Route path="business" element={<PartnerBusiness />} />
              <Route path="storage" element={<Storage />} />
              <Route path="transaction" element={<PartnerTransaction />} />
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
      <ReactQueryDevtools />
    </div>
  );
}

export default App;

import { ClockCircleFilled, StarFilled, StarOutlined } from "@ant-design/icons";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { DatePicker, message, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { CreateStripeSession } from "../../../common/api/stripe";
import { AntNotification } from "../../../common/components/notification/notification";
import UseAuth from "../../../common/hooks/UseAuth";
import "./LuggagePopup.css";
import dayjs from "dayjs";
import { deleteBooking } from "../../../common/api/booking";
import { useMutation } from "@tanstack/react-query";
import LoginModal from "../../../common/components/login/LoginModal";
import RegisterModal from "../../../common/components/register/RegisterModal";

const { Text } = Typography;

const PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(PUBLIC_KEY);

const LuggagePopup = ({ visible, onClose, data, selectedDate }) => {
  const getTodayDate = () => {
    const today = new Date(selectedDate);
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const defaultPickUpDate = () => {
    const today = new Date(selectedDate);
    const updatedDate = new Date(today.setDate(today.getDate() + 1));
    const year = updatedDate.getFullYear();
    const month = String(updatedDate.getMonth() + 1).padStart(2, "0");
    const day = String(updatedDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const { auth } = UseAuth();

  const [bags, setBags] = useState(1); // Default number of bags
  const [timeOption, setTimeOption] = useState("daily"); // "hourly" or "daily"
  const [days, setDays] = useState(1); // Number of days (default: 1)
  const [hours, setHours] = useState(1); // Number of hours (default: 0)
  const [totalPrice, setTotalPrice] = useState(0); // Total price calculation
  const [dropOffDate, setDropOffDate] = useState(getTodayDate()); // Drop-off date
  const [pickUpDate, setPickUpDate] = useState(defaultPickUpDate()); // Pick-up date
  const [sessionId, setSessionId] = useState(null); // Pick-up date

  const [isPaymentPopupVisible, setPaymentPopupVisible] = useState(false);

  const [isLogingModalVisible, setIsLoginModalVisible] = useState(false);
  const [isRegisterModalVisible, setIsRegisterModalVisible] = useState(false);

  const fetchClientSecret = async () => {
    // Create a Checkout Session
    const res = await CreateStripeSession({
      storageId: data.id,
      totalPrice: totalPrice,
      luggageSize: bags,
      bookingType: timeOption,
      days: days,
      hours: hours,
      dropOffDate: dropOffDate,
      pickUpDate: pickUpDate,
    });

    if (res.data.status === false) {
      setPaymentPopupVisible(false);
      return AntNotification("error", "Invalid Data", res.data.error.message);
    }
    setSessionId(res.data.sessionId);
    return res.data.clientSecret;
  };

  const options = { fetchClientSecret };

  useEffect(() => {
    if (dropOffDate && pickUpDate) {
      const dropDate = new Date(dropOffDate);
      const pickDate = new Date(pickUpDate);
      const differenceInTime = pickDate - dropDate;
      const calculatedDays = Math.ceil(
        differenceInTime / (1000 * 60 * 60 * 24)
      );
      setDays(calculatedDays > 0 ? calculatedDays : 1); // Ensure non-negative days
    }
  }, [dropOffDate, pickUpDate]);

  // Calculate total price when bags, rate, time option, days, or hours change
  useEffect(
    () => {
      let cost = 0;

      if (timeOption === "hourly") {
        setDropOffDate(hourTime.format("YYYY-MM-DD HH:mm:ss"));
        cost =
          data?.pricing?.hourlyRate * bags * hours +
          data?.pricing?.hourlyServiceCharge;
      } else if (timeOption === "daily") {
        cost =
          data?.pricing?.dailyRate * bags * days +
          data?.pricing?.dailyServiceCharge; // Daily rate assumes 24 hours
      }

      setTotalPrice(parseFloat(cost).toFixed(2)); // Add service fee ($4 in this example)
    },
    // eslint-disable-next-line
    [bags, timeOption, days, hours]
  );

  const handleBagsChange = (value) => {
    setBags(value || 0);
  };
  const hourTimeValue = () => {
    const now = new Date();
    let newHourTime = new Date(selectedDate);
    const isToday = newHourTime.toDateString() === now.toDateString();
    if (isToday) {
      const updatedTime = new Date(now.getTime() + 30 * 60 * 1000);
      newHourTime.setHours(updatedTime.getHours()); // Set hours
      newHourTime.setMinutes(updatedTime.getMinutes()); // Set minutes
      newHourTime.setSeconds(updatedTime.getSeconds());
    }
    return dayjs(newHourTime);
  };

  const [hourTime] = useState(hourTimeValue);

  const handleDropOffDateChange = (e) => {
    if (timeOption === "hourly") {
      if (e) {
        const formattedDate = e.format("YYYY-MM-DD HH:mm:ss"); // Format the date
        setDropOffDate(formattedDate); // Update state with formatted date
      }
    } else {
      const selectedDropDate = e.target.value; // New drop date selected by the user
      const dropDateObj = new Date(selectedDropDate);

      // Update dropDate state
      setDropOffDate(selectedDropDate);

      if (new Date(pickUpDate) <= dropDateObj) {
        const newPickDate = getFormattedDate(
          new Date(dropDateObj.setDate(dropDateObj.getDate() + 1))
        );
        setPickUpDate(newPickDate);
      }
    }
  };

  const handlePickUpDateChange = (e) => {
    setPickUpDate(e.target.value);
  };

  const isStoreOpenOnDate = (storeData, selectedDate) => {
    const date = new Date(selectedDate);
    const dayOfWeek = date.toLocaleString("en-US", { weekday: "long" });

    const daySchedule = storeData.openingHours[dayOfWeek];
    if (!daySchedule || !daySchedule.open) {
      // Store is closed on this day
      return false;
    }

    // Store is open, check if it has any hours
    const hours = daySchedule.hours;
    return hours && hours.length > 0;
  };

  const handleSubmitForm = async () => {
    let isValid = true;

    if (!isStoreOpenOnDate(data, dropOffDate)) {
      AntNotification(
        "warning",
        "Store Closed",
        "Please note that store is closed on the selected date"
      );
      isValid = false; // Disable if open is false
    }
    if (!isStoreOpenOnDate(data, pickUpDate)) {
      AntNotification(
        "warning",
        "Store Closed",
        "Please note that store is closed on the selected date"
      );
      isValid = false; // Disable if open is false
    }

    const dropDate = new Date(dropOffDate);
    const pickDate = new Date(pickUpDate);
    const differenceInTime = pickDate - dropDate;

    if (differenceInTime / (1000 * 60 * 60 * 24) < 0) {
      AntNotification(
        "error",
        "Invalid Days",
        "pickup date should be greater than or equal to drop off date"
      );
    }

    if (timeOption === "hourly" && hours <= 0) {
      AntNotification(
        "error",
        "Invalid Hours",
        "Number of hours must be greater than 0 for hourly bookings."
      );
      isValid = false; // Disable if hours are 0
    }
    if (timeOption === "daily" && (days <= 0 || days == null)) {
      AntNotification(
        "error",
        "Invalid Days",
        "Number of days must be greater than 0 for daily bookings."
      );
      isValid = false; // Disable if days are 0
    }

    if (isValid) {
      setPaymentPopupVisible(true);
    }
  };

  useEffect(
    () => {
      if (timeOption === "hourly") {
        handleHourChange(1);
      }
    },
    // eslint-disable-next-line
    [dropOffDate]
  );

  const handleHourChange = (hours) => {
    if (hours >= 24) {
      return AntNotification(
        "error",
        "Maximum Hours Reached",
        "Hours should be less than 24"
      );
    }

    setHours(hours);
    const [datePart, timePart] = dropOffDate.split(" ");
    const [year, month, hourDay] = datePart.split("-").map(Number);
    const [dateHour, minute, second] = timePart.split(":").map(Number);
    const date = new Date(year, month - 1, hourDay, dateHour, minute, second);
    // const date = new Date();
    date.setHours(date.getHours() + hours);
    const updatedDateTime = `${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")} ${String(
      date.getHours()
    ).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}:${String(
      date.getSeconds()
    ).padStart(2, "0")}`;
    setPickUpDate(updatedDateTime);
  };

  const now = new Date(); // Current date and time
  const thresholdTime = new Date(now.getTime() + 30 * 60 * 1000); // Subtract 30 minutes from current time

  // Disable dates before today
  const disabledDate = (currentDate) => {
    return (
      currentDate && currentDate.toDate() < new Date().setHours(0, 0, 0, 0)
    );
  };

  // Disable times before the threshold (30 minutes before current time)
  const disabledTime = (currentDate) => {
    if (
      currentDate &&
      currentDate.toDate().toDateString() === now.toDateString()
    ) {
      return {
        disabledHours: () =>
          Array.from({ length: 24 }, (_, i) => i).filter(
            (hour) => hour < thresholdTime.getHours()
          ),
        disabledMinutes: (selectedHour) => {
          if (selectedHour === thresholdTime.getHours()) {
            return Array.from({ length: 60 }, (_, i) => i).filter(
              (minute) => minute < thresholdTime.getMinutes()
            );
          }
          return [];
        },
        disabledSeconds: () => [], // No specific seconds disabled in this example
      };
    }
    return {};
  };

  const getFormattedDate = (date) => {
    if (!date || !(date instanceof Date)) return "";

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const deleteBookingFunc = useMutation({
    mutationFn: ({ sessionId }) => deleteBooking(sessionId),
    onSuccess: (data) => {
      onClose();
      setSessionId(null);
    },
    onError: (error) => {
      message.error(error.response.data.error.message);
      onClose();
    },
  });

  async function handleClose() {
    if (sessionId) {
      deleteBookingFunc.mutate({ sessionId });
    } else {
      onClose();
    }
  }

  if (!visible) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-container">
        {!isPaymentPopupVisible ? (
          <>
            <div className="popup-header">
              <h2>{data.title}</h2>
              <button className="close-button" onClick={handleClose}>
                &times;
              </button>
            </div>
            <div className="popup-content">
              <p className="popup-subtitle">{data?.subTitle}</p>
              <div className="popup-rating">
                {/*<Text>  {data.rating} <StarOutlined style={{ color: "#ffc107" }} /> ({data.reviews} reviews) </Text>*/}
                <Text>
                  {" "}
                  {data.rating} <StarOutlined style={{ color: "#ffc107" }} /> (
                  {data.reviews} reviews){" "}
                </Text>
              </div>
              <div className="popup-tags">
                {data?.tags?.map((tag, index) =>
                  tag.label === "Highly Rated" && data.rating <= 4.0 ? null : (
                    <Tag
                      key={index}
                      className="popup-tag"
                      color={tag.color}
                      icon={
                        tag.label === "Highly Rated" ? (
                          <StarFilled />
                        ) : tag.label === "Open Late" ? (
                          <ClockCircleFilled />
                        ) : null
                      }
                    >
                      {tag.label}
                    </Tag>
                  )
                )}
              </div>
              <div>
                <p>
                  <strong>Location:</strong> Lat: {data.location.lat}, Lng:{" "}
                  {data.location.lng}
                </p>
              </div>

              {timeOption === "daily" ? (
                <div className="booking-dates">
                  <div className="booking-dates-drop-off">
                    <label className="popup-label">Drop off</label>
                    <input
                      type="date"
                      className="popup-input"
                      value={dropOffDate || getTodayDate()}
                      min={dropOffDate || getTodayDate()} // Set minimum date to today's date
                      onChange={handleDropOffDateChange}
                    />
                  </div>

                  <div className="booking-dates-pickup">
                    <label className="popup-label">Pick up</label>
                    <input
                      type="date"
                      className="popup-input"
                      value={pickUpDate || getTodayDate()}
                      min={dropOffDate || getTodayDate()} // Ensure pick-up date is not before drop-off date
                      onChange={handlePickUpDateChange}
                    />
                  </div>
                </div>
              ) : (
                hourTime && (
                  <div className="booking-dates">
                    <div className="booking-dates-drop-off">
                      <label className="popup-label">Drop off</label>
                      <DatePicker
                        showTime
                        defaultValue={hourTime}
                        disabledDate={disabledDate}
                        disabledTime={disabledTime}
                        format="YYYY-MM-DD HH:mm:ss"
                        style={{ width: "100%" }}
                        onChange={handleDropOffDateChange}
                      />
                    </div>
                  </div>
                )
              )}

              <label className="popup-label">How many bags?</label>
              <input
                type="number"
                className="popup-input"
                value={bags}
                min={1}
                onChange={(e) => handleBagsChange(parseInt(e.target.value))}
              />

              <div className="time-selection">
                {data?.pricing?.isDaily ? (
                  <label>
                    <input
                      type="radio"
                      name="timeOption"
                      value="daily"
                      checked={timeOption === "daily"}
                      onChange={() => setTimeOption("daily")}
                    />
                    Daily
                  </label>
                ) : null}
                {data?.pricing?.isHourly ? (
                  <label>
                    <input
                      type="radio"
                      name="timeOption"
                      value="hourly"
                      checked={timeOption === "hourly"}
                      onChange={() => setTimeOption("hourly")}
                    />
                    Hourly
                  </label>
                ) : null}
              </div>

              {timeOption === "daily" ? (
                <label className="popup-label">Number of days: {days}</label>
              ) : (
                <div>
                  <label className="popup-label">
                    Number of hours:
                    <input
                      type="number"
                      className="popup-input"
                      value={hours}
                      min="1"
                      onChange={(e) =>
                        handleHourChange(parseInt(e.target.value))
                      }
                    />
                  </label>
                  <label className="popup-label">Drop Off Date Time </label>
                  {dropOffDate}
                  <label className="popup-label">Pick Up Date Time </label>
                  {pickUpDate}
                </div>
              )}

              <div className="summary-section">
                <h3>Summary</h3>
                {timeOption === "hourly" ? (
                  <>
                    <p>
                      Storage for {bags} bag(s) at £{data.pricing.hourlyRate}
                      /hour
                    </p>
                    <p>Service fee: £{data.pricing.hourlyServiceCharge}</p>
                  </>
                ) : timeOption === "daily" ? (
                  <>
                    <p>
                      Storage for {bags} bag(s) at £{data.pricing.dailyRate}
                      /day
                    </p>
                    <p>Service fee: £{data.pricing.dailyServiceCharge}/day</p>
                  </>
                ) : null}
                {/*<div className="insurance-section">*/}
                {/*  <input*/}
                {/*    type="checkbox"*/}
                {/*    checked={insurance}*/}
                {/*    onChange={handleInsuranceChange}*/}
                {/*    id="insurance"*/}
                {/*  />*/}
                {/*  <label htmlFor="insurance">Insurance (+$5.90)</label>*/}
                {/*</div>*/}
                {/*<p className="free-cancellation">Free cancellation: Included</p>*/}
              </div>

              <div className="due-now-section">
                {/* <h3>Total Price: £{totalPrice.toFixed(2)}</h3> */}
                <h3>Total Price: £{totalPrice}</h3>
                <p>* Calculated based on selected options</p>
              </div>

              {auth ? (
                <button
                  onClick={handleSubmitForm}
                  className="confirm-button yellow-btn"
                >
                  Confirm Booking
                </button>
              ) : (
                <button
                  onClick={() => setIsLoginModalVisible(true)}
                  className="confirm-button yellow-btn"
                >
                  Login for Booking
                </button>
              )}
            </div>
          </>
        ) : (
          <div className="popup-overlay">
            <div className="popup-container">
              <div className="popup-header">
                <h2>Payment Gateway</h2>
                <button
                  className="close-button"
                  onClick={() => {
                    setPaymentPopupVisible(false);
                    handleClose();
                  }}
                >
                  &times;
                </button>
              </div>
              <div className="popup-content">
                <div id="checkout">
                  {options && (
                    <EmbeddedCheckoutProvider
                      stripe={stripePromise}
                      options={options}
                    >
                      <EmbeddedCheckout />
                    </EmbeddedCheckoutProvider>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <LoginModal
          isModalVisible={isLogingModalVisible}
          handleCancel={() => setIsLoginModalVisible(false)}
          setRegisterModalVisible={() => setIsRegisterModalVisible(true)}
        />
        <RegisterModal
          isModalVisible={isRegisterModalVisible}
          handleCancel={() => setIsRegisterModalVisible(false)}
          setLoginModalVisible={() => setIsLoginModalVisible(true)}
        />
      </div>
    </div>
  );
};

export default LuggagePopup;

/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {useQuery} from "@tanstack/react-query";
import {
  Button,
  Divider,
  Image,
  List,
  Rate,
  Skeleton,
  Tag,
  Typography,
} from "antd";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {getStorageById} from "../../../../common/api/storage";
import {formatHours, sortWeekDays} from "../../../../common/util";
import LuggagePopup from "../../modal/LuggagePopup";
import StorePriceModal from "../StorePrice/StorePriceModal";
import "./StoreSection.css";
import {EnvironmentOutlined} from "@ant-design/icons";

const {Title, Text, Paragraph} = Typography;

const StoreSection = ({onSeeComments}) => {
  const {id} = useParams(); // Extract id from the URL

  const storageQuery = useQuery({
    queryKey: ["storage", id],
    queryFn: () => getStorageById(id),
    staleTime: 1000 * 60 * 15, // 15 minutes
  });

  const data = storageQuery.data?.data?.success.data;
  const googleMapsUrl = `https://www.google.com/maps?q=${data?.latitude},${data?.longitude}`;
  useEffect(() => {
    if (data) {
      data["location"] = {
        lat: data.latitude,
        lng: data.longitude,
      };
      // data["pricing"] = {
      //   dailyRate: data?.pricing.dailyRate,
      //   hourlyRate: data?.pricing.hourlyRate,
      //   dailyServiceCharge: data?.pricing.dailyServiceCharge,
      //   hourlyServiceCharge: data?.pricing.hourlyServiceCharge,
      // };
      data.reviews = data.review_count;
    }
  }, [data]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Navigates back to the previous page
  };

  // Close the modal
  const handleClose = () => {
    setIsModalVisible(false);
  };

  const [isBookingModalVisible, setBookingModalVisible] = useState(false);
  const [isPaymentPopupVisible, setPaymentPopupVisible] = useState(false);

  const showBookingModal = () => {
    setBookingModalVisible(true);
  };

  const closeBookingModal = () => {
    setBookingModalVisible(false);
  };

  const handleSubmit = () => {
    setPaymentPopupVisible(true);
    closeBookingModal();
  };
  const pricingData = {
    title: "Price per bag",
    prices: [
      {amount: data?.pricing.hourlyRate, unit: "hour"},
      {amount: data?.pricing.dailyRate, unit: "day"},
    ],
    details: [
      "You have the flexibility to choose between hourly or daily rates when making your booking.",
      "Prices apply to bookings made in advance. Walk-in rates may be higher",
      "Discounts are automatically applied for multi-day bookings and large online reservations",
    ],
    buttonText: "OK, got it",
  };

  return (
    <>
      {storageQuery.isLoading ? (
        <Skeleton/>
      ) : (
        <div
          style={{
            maxWidth: "80vw",
            margin: "20px auto",
            padding: "20px",
            borderRadius: "12px",
          }}
        >
          <div className="title-rating">
            {/* Header */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <Title level={3}>{data.title}</Title>
              </div>
            </div>

            {/* Ratings */}
            <div style={{margin: "20px 0", paddingTop: "20px"}}>
              <Rate
                disabled
                defaultValue={data.average_rating}
                style={{color: "#faad14"}}
              />
              <Text style={{marginLeft: "8px"}}>
                ({data.average_rating} ratings)
              </Text>
              <Paragraph>
                <Text strong>{data.review_count} travelers</Text> gave this
                place 5 out of {data.average_rating} stars!{" "}
                <span
                  onClick={onSeeComments}
                  style={{color: "#1890ff", cursor: "pointer"}}
                >
                  See what they said.
                </span>
              </Paragraph>
            </div>
          </div>

          <div
            className="store-images"
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {data && data.images?.length > 0 ? (
              <Image.PreviewGroup>
                {data.images.map((image, index) => {
                  return (
                    <div key={index}>
                      <Image
                        style={{padding: 5}}
                        width={350}
                        src={image.url}
                      />
                    </div>
                  );
                })}
              </Image.PreviewGroup>
            ) : (
              ""
            )}
            <br/>
          </div>

          {data && (
            <div>
              <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer">
                <Button
                  type="primary"
                  icon={<EnvironmentOutlined/>}
                  className="map-button"
                >
                  View Location on Google Maps
                </Button>
              </a>
            </div>
          )}

          {/* Pricing */}
          <div style={{margin: "20px 0"}}>
            <Title level={4}>Pricing</Title>
            <Paragraph>
              {/* {data.pricing.hourlyRate} or {data.pricing.dailyRate} per bag. */}
              <b>£ {data?.pricing.hourlyRate}</b>/hourly
              {" or "}
              <b>£ {data?.pricing.dailyRate}</b>/daily per bag.
              <br/>
              Book ahead to get the best rates.
            </Paragraph>
            <span
              // href={data.pricing.detailsLink}
              onClick={showModal}
              style={{color: "#1890ff", cursor: "pointer"}}
            >
              Details
            </span>
          </div>
          <Button
            style={{border: "none"}}
            type="primary"
            className="yellow-btn"
            onClick={showBookingModal}
          >
            Book Now
          </Button>

          {/* Opening Hours */}
          <div style={{margin: "20px 0"}}>
            <Title level={4}>Opening Hours</Title>
            <List
              dataSource={Object.entries(sortWeekDays(data?.openingHours))} // Convert storeHours object to an array
              renderItem={([day, {open, hours}]) => (
                <List.Item>
                  <Text style={{marginRight: "10px"}} strong>
                    {day}{" "}
                  </Text>{" "}
                  {open ? (
                    <>
                      <Tag color="green">OPEN</Tag>
                      <pre
                        style={{
                          margin: "5px 0",
                          fontSize: "medium",
                          fontFamily: "Roboto Mono, monospace",
                        }}
                      >
                        {formatHours(hours)}
                      </pre>
                    </>
                  ) : (
                    <Tag color="red">CLOSED</Tag>
                  )}
                </List.Item>
              )}
            />
          </div>

          {/* About */}
          <div style={{margin: "20px 0"}}>
            <Title level={4}>About</Title>
            <div style={{display: "flex", flexWrap: "wrap", gap: "12px"}}>
              {data?.tags?.map((item, index) => (
                <Tag key={index} icon={item.icon} color={item.color}>
                  {item?.label}
                </Tag>
              ))}
            </div>
          </div>

          {/* How It Works */}
          <div style={{margin: "20px 0"}}>
            <Title level={4}>How it works</Title>
            <Paragraph>{data.description}</Paragraph>
          </div>
          <LuggagePopup
            data={data}
            visible={isBookingModalVisible}
            onClose={closeBookingModal}
            handleSubmit={handleSubmit}
            selectedDate={new Date()}
          />
          {isPaymentPopupVisible && (
            <div className="popup-overlay">
              <div className="popup-container">
                <div className="popup-header">
                  <h2>Payment Gateway</h2>
                  <button
                    className="close-button"
                    onClick={() => setPaymentPopupVisible(false)}
                  >
                    &times;
                  </button>
                </div>
                <div className="popup-content">
                  <p>Payment Gateway Under Development</p>
                  <p>Please check again later!</p>
                </div>
              </div>
            </div>
          )}
          {/* Not Relevant */}
          <Divider/>
          <div style={{textAlign: "left"}}>
            {/* <Paragraph>{data.notRelevant.message}</Paragraph> */}
            <Button
              className="yellow-btn"
              onClick={handleGoBack}
              type="primary"
              size="large"
              // href={data.notRelevant.moreShopsLink}
            >
              See more shops
            </Button>
          </div>
          <StorePriceModal
            visible={isModalVisible}
            onClose={handleClose}
            pricing={pricingData}
          />
        </div>
      )}
    </>
  );
};

export default StoreSection;

/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import "./Footer.css"
import {Link} from "react-router-dom";


const GlobalFooter = () => {
  return (
    <div className="row">
      <div className="footer-contents">
        <div className="footer-links">
          <Link to="/contact-us">Contact Us</Link>
          {/*<Link to="/about">About Us</Link>*/}
          <Link to="/terms-and-conditions">Terms & Conditions</Link>
          {/*<Link to="/careers">Careers</Link>*/}
        </div>
      </div>
      <div className="copy">
        &copy; Copyright {new Date().getFullYear()} - CRYPTO LUGGAGE (PVT) LTD. All Rights Reserved.
      </div>
    </div>
  );
};

export default GlobalFooter;

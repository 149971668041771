/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  AppstoreOutlined,
  BankOutlined,
  CalendarOutlined,
  DesktopOutlined,
  DollarCircleOutlined,
  PieChartOutlined,
  TagOutlined,
  TransactionOutlined,
  UserOutlined
} from "@ant-design/icons";
import {Link} from "react-router-dom";
import React from "react";

export const bookingStatusArray = [
  {
    label: "PENDING",
    value: "pending",
  },
  {
    label: "IN-PROGRESS",
    value: "in-progress",
  },
  {
    label: "CANCELED",
    value: "cancelled",
  },
  {
    label: "CONFIRMED",
    value: "confirmed",
  },
  {
    label: "COMPLETED",
    value: "completed",
  },
];

export const bookingTypeArray = [
  {
    label: "HOURLY",
    value: "hourly",
  },
  {
    label: "DAILY",
    value: "daily",
  },
];

export const storageStatusArray = [
  {
    label: "PENDING",
    value: "pending",
  },
  {
    label: "APPROVED",
    value: "approved",
  },
  {
    label: "DEACTIVATED",
    value: "deactivate",
  },
];

export const userTypeArray = [
  {
    label: "ADMIN",
    value: "admin",
  },
  {
    label: "MANAGER",
    value: "manager",
  },
  {
    label: "USER",
    value: "user",
  },
];

export const userStatusArray = [
  {
    label: "VERIFIED",
    value: "verified",
  },
  {
    label: "PENDING",
    value: "pending",
  },
  {
    label: "BLOCKED",
    value: "blocked",
  },
];

export const paymentStatusArray = [
  {
    label: "PENDING",
    value: "pending",
  },
  {
    label: "PAID",
    value: "paid",
  },
  {
    label: "FAILED",
    value: "failed",
  },
];

export const transationStatusArray = [
  {
    label: "PENDING",
    value: "pending",
  },
  {
    label: "SUCCEEDED",
    value: "succeeded",
  },
  {
    label: "CANCELED",
    value: "canceled",
  },
];

export const paymentCategoryArray = [
  {label: "PAYOUT", value: "payout"},
  {label: "PAYIN", value: "payin"},
];


export const partnerMenuItems = [
  {
    key: '1',
    icon: <PieChartOutlined/>,
    label: (
      <Link to="/partner/dashboard">
        Dashboard
      </Link>
    ),
  },
  {
    key: '2',
    icon: <BankOutlined/>,
    label: (
      <Link to="/partner/business">
        Business
      </Link>
    ),
  },
  {
    key: '3',
    icon: <AppstoreOutlined/>,
    label: (
      <Link to="/partner/storage">
        Storage
      </Link>
    ),
  },
  {
    key: '4',
    icon: <TransactionOutlined/>,
    label: (
      <Link to="/partner/transaction">
        Transaction
      </Link>
    ),
  }
];

export const userMenuItems = [
  // {
  //   key: '1',
  //   icon: <PieChartOutlined/>,
  //   label: (
  //     <Link to="/user/dashboard">
  //       Dashboard
  //     </Link>
  //   ),
  // },
  {
    key: '2',
    icon: <CalendarOutlined/>,
    label: (
      <Link to="/user/booking">
        Booking
      </Link>
    ),
  },
  // {
  //   key: '3',
  //   icon: <AppstoreOutlined/>,
  //   label: (
  //     <Link to="/user/storage">
  //       Storage
  //     </Link>
  //   ),
  // },
  // {
  //   key: '4',
  //   icon: <TransactionOutlined/>,
  //   label: (
  //     <Link to="/user/transaction">
  //       Transaction
  //     </Link>
  //   ),
  // }
];

export const adminMenuItems = [
  {
    key: '1',
    icon: <PieChartOutlined/>,
    label: (
      <Link to="/admin/dashboard">
        Dashboard
      </Link>
    ),
  },
  {
    key: '2',
    icon: <DesktopOutlined/>,
    label: (
      <Link to="/admin/booking">
        Booking
      </Link>
    ),
  },
  {
    key: '3',
    icon: <AppstoreOutlined/>,
    label: (
      <Link to="/admin/storage">
        Storage
      </Link>
    ),
  },
  {
    key: '4',
    icon: <TagOutlined/>,
    label: (
      <Link to="/admin/price">
        Price
      </Link>
    ),
  },
  {
    key: '5',
    icon: <UserOutlined/>,
    label: (
      <Link to="/admin/users">
        Users
      </Link>
    ),
  },
  {
    key: '6',
    icon: <TransactionOutlined/>,
    label: (
      <Link to="/admin/transaction">
        Transaction
      </Link>
    ),
  },
  {
    key: '7',
    icon: <DollarCircleOutlined/>,
    label: (
      <Link to="/admin/payout">
        Payout
      </Link>
    ),
  },
];
/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export const API_URLS = {
  LOGIN_URL: "/auth/login",
  REGISTER_URL: "/auth/register",
  ACCOUNT_VERIFICATION_URL: "/auth/active",
  LOGOUT_URL: "/auth/logout",

  GET_ME_URL: "token/me",

  REFRESH_TOKEN_URL: "token/access/generate",

  GET_ALL_USERS_URL: "user/all",
  POST_NEW_MANAGER_URL: "user/create/manager",
  UPDATE_USER_URL: "user/update",
  UPDATE_USER_STATUS_URL: "user/update-status",
  DELETE_USER_URL: "user/delete",
  UPDATE_USER_PASSWORD_URL: "user/update-password",

  CREATE_STORAGE_URL: "storage/create/",
  UPDATE_STORAGE_STATUS_URL: "storage/update-status/",
  UPDATE_STORAGE_DATA_URL: "storage/update-data/",
  GET_ALL_STORAGE_URL: "storage/all/",
  GET_STORAGE_URL: "storage/user/",
  GET_STORAGE_BY_ID_URL: "storage/",
  GET_STORAGE_NEAR_BY_URL: "storage/nearby/paginated",
  UPDATE_STORAGE_OPEN_HOURS: "storage/update/opening-hours/",
  UPDATE_STORAGE_PRICING: "storage/update/pricing/",
  UPDATE_STORAGE_CONTACT: "storage/update/contact-info/",
  UPDATE_STORAGE_IMAGES: "storage/update/images/",

  GET_STORAGE_BOOKING_BY_USERID_URL: "booking/user/storage/",
  GET_BOOKING_BY_STORAGE_ID_URL: "booking/storage/",
  GET_BOOKING_BY_USER_ID_URL: "booking/user/",
  GET_ALL_URL: "booking/all/",
  UPDATE_BOOKING_STATUS_URL: "booking/status/",
  DELETE_BOOKING_URL: "booking/",

  CREATE_BANK_ACCOUNT_URL: "account/create/",
  UPDATE_BANK_ACCOUNT_URL: "account/user/",
  GET_BANK_ACCOUNTS_BY_USERID_URL: "account/user/",

  GET_REVIEWS_BY_STORAGE_ID_URL: "review/storage/paginated",
  ADD_REVIEW_URL: "review/create/",

  POST_LIKE_URL: "like/toggle/",

  POST_PAYOUT_TRANSACTION_URL: "stripe/make-payment",
  CREATE_STRIPE_SESSION_URL: "stripe/create-checkout-session",
  GET_STRIPE_PAYMENT_STATUS_URL: "stripe/session-status",
  CREATE_STRIPE_BANK_ACCOUNT_URL: "account/create/",
  UPDATE_STRIPE_BANK_ACCOUNT_URL: "account/user/",

  GET_PAYOUT_TRANSACTION_URL: "transaction/payout",
  GET_ALL_TRANSACTION_URL: "transaction/all",
  GET_BOOKING_TRANSACTION_URL: "transaction/booking/",
  GET_USER_TRANSACTION_URL: "transaction/user/",

  GET_STATISTICS_URL: "statistics/month",

  UPLOAD_FILE_URL: "file/idenfitication/upload",
  UPLOAD_MULTIPLE_FILE_URL: "file/images/upload",
  UPDATE_MULTIPLE_FILE_URL: "file/images/update",

  CONTACT_US_URL: "/contact",

  POST_STORAGE_PRICING_URL: "service-charge/create",
  GET_ALL_SERVICE_CHARGES_URL: "service-charge/all",
};

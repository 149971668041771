/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useState} from 'react';
import {Button, Form, Input, InputNumber, message} from "antd";
import {useMutation} from "@tanstack/react-query";
import {updateStorageContact} from "../../../common/api/storage";

const EditStoreContact = ({contactWebsite, contactEmail, contactTel, contactName, capacity,storageId}) => {
  const [isEditing, setIsEditing] = useState(false); // State to toggle edit mode
  const [form] = Form.useForm();

  // Initial field values
  const initialValues = {
    contact_website: contactWebsite,
    contact_email: contactEmail,
    contact_mobile: contactTel,
    contact_name: contactName,
    capacity: capacity, // Numeric default value
  };

  // Handle Edit Button Click
  const handleEdit = () => {
    setIsEditing(true); // Enable editing
  };

  // Handle Save Button Click
  const handleSave = () => {
    form.validateFields()
      .then((values) => {
        updateStorageContactFunc.mutate({
          id: storageId,
          data: {
            contactWebsite: values.contact_website,
            contactEmail: values.contact_email,
            contactMobile: values.contact_mobile,
            contactName: values.contact_name,
            capacity: values.capacity,
          }
        })
        setIsEditing(false); // Disable editing after saving
      })
      .catch((info) => {
       message.error(info);
      });
  };

  const updateStorageContactFunc = useMutation({
    mutationFn: ({id, data}) => updateStorageContact(id, data),
    onSuccess: (data) => {
      message.success(data.data.success.message).then(() => {
      });
    },
    onError: (error) => {
      message.error(error.response.data.error.message);
    },
  });

  return (
    <div style={{ maxWidth: 400 }}>
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
      >
        {/* Contact Name Field */}
        <Form.Item
          label="Contact Name"
          name="contact_name"
          rules={[{ required: true, message: "Contact name is required!" }]}
        >
          {!isEditing ? (
            <div>{form.getFieldValue("contact_name") || "N/A"}</div>
          ) : (
            <Input />
          )}
        </Form.Item>

        {/* Contact Email Field */}
        <Form.Item
          label="Contact Email"
          name="contact_email"
          rules={[
            { required: true, message: "Contact email is required!" },
            { type: "email", message: "Please enter a valid email!" },
          ]}
        >
          {!isEditing ? (
            <div>{form.getFieldValue("contact_email") || "N/A"}</div>
          ) : (
            <Input />
          )}
        </Form.Item>

        {/* Contact Website Field */}
        <Form.Item
          label="Contact Website"
          name="contact_website"
        >
          {!isEditing ? (
            <div>{form.getFieldValue("contact_website") || "N/A"}</div>
          ) : (
            <Input />
          )}
        </Form.Item>

        {/* Contact Mobile Field */}
        <Form.Item
          label="Contact Mobile"
          name="contact_mobile"
          rules={[
            { required: true, message: "Contact mobile is required!" },
            {
              pattern: /^\d+$/,
              message: "Contact mobile must be numeric!",
            },
          ]}
        >
          {!isEditing ? (
            <div>{form.getFieldValue("contact_mobile") || "N/A"}</div>
          ) : (
            <Input
            />
          )}
        </Form.Item>

        {/* Capacity Field */}
        <Form.Item
          label="Capacity"
          name="capacity"
          rules={[{ required: true, message: "Capacity is required!" }]}
        >
          {!isEditing ? (
            <div>{Number(form.getFieldValue("capacity") || 0).toFixed(0)}</div>
          ) : (
            <InputNumber min={10} style={{ width: "100%" }} />
          )}
        </Form.Item>

        {/* Action Buttons */}
        <Form.Item>
          {!isEditing ? (
            <Button onClick={handleEdit}>
              Edit
            </Button>
          ) : (
            <Button onClick={handleSave} htmlType="submit">
              Save
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );

};

export default EditStoreContact;
/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import { Col, Form, Input, Row, Typography } from "antd";

const ThirdStep = () => {
  return (
    <div className="third-step-form" style={{ padding: "0 5px" }}>
      <Typography.Title level={3}>Contact Information</Typography.Title>

      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Form.Item
            label="Contact Name"
            name="contactName"
            rules={[
              { required: true, message: "Please enter the contact name" },
            ]}
          >
            <Input placeholder="Contact Name" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24}>
          <Form.Item
            label="Email Address"
            name="contactEmail"
            rules={[
              { required: true, message: "Please enter the email address" },
              { type: "email", message: "Please enter a valid email address" },
            ]}
          >
            <Input placeholder="Email Address" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24}>
          <Form.Item
            label="Mobile Number"
            name="contactMobile"
            rules={[
              { required: true, message: "Please input your phone number!" },
              {
                pattern: /[+][0-9]{10}[0-9]{0,5}$/,
                message:
                  "Please enter a valid mobile number(Ex: +911234567890).",
              },
            ]}
          >
            <Input placeholder="Mobile Number" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24}>
          <Form.Item
            label="Website"
            name="contactWebsite"
            rules={[
              { required: false },
              { type: "url", message: "Please enter a valid URL" },
            ]}
          >
            <Input placeholder="Website" />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default ThirdStep;

/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useEffect, useRef, useState } from "react";
import { DatePicker, Input, Select } from "antd";
import "./NavSearchBar.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { googleMapsConfig } from "../../configs/googleMap";

const { Option } = Select;

dayjs.extend(utc);
dayjs.extend(timezone);

const NavSearchBar = () => {
  const [searchParams] = useSearchParams();

  const inputRef = useRef(null);
  const [address, setAddress] = useState(searchParams.get("address") || "");

  const { isLoaded } = useJsApiLoader({
    id: googleMapsConfig.id,
    googleMapsApiKey: googleMapsConfig.googleMapsApiKey,
    libraries: googleMapsConfig.libraries,
  });

  // Initializing state from query params
  const [searchData, setSearchData] = useState();

  useEffect(() => {
    setSearchData({
      date: searchParams.get("date") || "",
      luggage: searchParams.get("luggage") || "",
      lat: searchParams.get("lat") || "",
      lng: searchParams.get("lng") || "",
      address: searchParams.get("address") || "",
    });
  },
    // eslint-disable-next-line
    []);

  const handlePlaceChanged = () => {
    const place = inputRef.current.getPlaces()[0];
    const selectedAddress = place.formatted_address || place.name;
    const lat = place.geometry?.location?.lat();
    const lng = place.geometry?.location?.lng(); // Use formatted_address if available
    setAddress(selectedAddress); // Update the input field with the selected address
    setSearchData((prev) => ({
      ...prev,
      lat: lat,
      lng: lng,
      address: selectedAddress,
    }));
  };

  const disabledDate = (current) => {
    const now = dayjs(); // Get the current date and time
    const endOfToday = dayjs().endOf("day"); // Get the end of today

    // Check if the current date is in the past or if today has less than 2 hours remaining
    return (
      current &&
      (current.isBefore(dayjs().startOf("day")) || // Disable past dates
        (current.isSame(dayjs(), "day") && endOfToday.diff(now, "hour") < 1)) // Disable today if less than 2 hours left
    );
  };

  const navigate = useNavigate();

  const handleChange = (name, value) => {
    if (name === 'date') {
      const localDate = value.tz(dayjs.tz.guess())
      setSearchData((prev) => ({...prev, [name]:  localDate.format("YYYY-MM-DD HH:mm:ss")}));
    } else {
      setSearchData((prev) => ({...prev, [name]: value}));
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(searchData).toString();
    navigate({ search: queryParams });
  },
    // eslint-disable-next-line
    [searchData]);

  return (
    <div className="nav-search-bar-container">
      <div className="nav-search-bar">
        <div className="nav-search-item nav-location">
          {/* <label>Location</label> */}
          {isLoaded && (
            <StandaloneSearchBox
              onLoad={(ref) => (inputRef.current = ref)}
              onPlacesChanged={handlePlaceChanged}
            >
              <Input
                placeholder="Enter location"
                onChange={(e) => setAddress(e.target.value)}
                variant="borderless"
                value={address}
              />
            </StandaloneSearchBox>
          )}
        </div>
        <div className="nav-divider" />
        <div className="nav-search-item nav-date nav-centered-datepicker">
          {/* <label>Date</label> */}
          <DatePicker
            onChange={(date) =>
              handleChange("date", date ? date : "")
            }
            format="MM/DD/YYYY"
            variant="borderless"
            size={"middle"}
            placement={"bottomLeft"}
            disabledDate={disabledDate}
            placeholder="Date"
            value={searchData?.date ? dayjs(searchData?.date) : null}
          />
        </div>
        <div className="nav-divider" />
        <div className="nav-search-item nav-luggage">
          <label>Luggage</label>
          <Select
            onChange={(value) => handleChange("minCapacity", value)}
            variant="filled"
            defaultValue={searchParams ? searchParams?.get("luggage") : 1}
            style={{width: 80}}
          >
            {[...Array(50).keys()].map((num) => (
              <Option key={num + 1} value={num + 1}>
                {num + 1}
              </Option>
            ))}
          </Select>
        </div>
        {/*<Button type="primary" className="nav-search-button">*/}
        {/*  <span className="nav-search-icon">*/}
        {/*    <SearchOutlined />*/}
        {/*  </span>*/}
        {/*  <span className="nav-search-text">Search</span>*/}
        {/*</Button>*/}
      </div>
    </div>
  );
};

export default NavSearchBar;

/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useState } from "react";
import { Modal, Skeleton, Tag } from "antd";
import "./PayoutModal.css"; // Optional: Import custom styles
import { useQuery } from "@tanstack/react-query";
import { getBookingTransactionsByUserId } from "../../../../common/api/transaction";
import TableAntd from "../../../../common/components/table/TableAntd";

const PayoutModal = ({ transaction, visible, onClose }) => {
  const { user_id } = transaction;

  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });

  const payoutQuery = useQuery({
    queryKey: ["payout", "user", user_id],
    queryFn: () => getBookingTransactionsByUserId(user_id),
    staleTime: 1000 * 60 * 15, // 15 minutes
  });

  const data = payoutQuery.data?.data?.success.data;

  const columns = [
    {
      title: "User Name",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "Store Name",
      dataIndex: "storage_name",
      key: "storage_name",
    },
    {
      title: "Booking Id",
      dataIndex: "booking_id",
      key: "booking_id",
    },
    {
      title: "Payout Amount",
      dataIndex: "storage_charge",
      key: "storage_charge",
    },
    {
      title: "Payout Status",
      dataIndex: "payout_status",
      key: "payout_status",
      render: (_, { payout_status }) => (
        <Tag
          color={
            payout_status === "pending"
              ? "yellow"
              : payout_status === "succeeded"
              ? "green"
              : "volcano"
          }
          key={payout_status}
        >
          {payout_status.toUpperCase()}
        </Tag>
      ),
    },
  ];

  const handleTableChange = (pagination) => {
    setParams({
      ...params,
      page: pagination.current,
      limit: pagination.pageSize,
    });
  };

  return (
    <Modal
      title={`Payout Details`}
      open={visible}
      onCancel={onClose}
      footer={null}
      width={800}
    >
      {payoutQuery.isLoading ? (
        <Skeleton />
      ) : (
        <TableAntd
          columns={columns}
          data={data}
          total={data?.length}
          handleTableChange={handleTableChange}
          current={params?.page}
          pageSize={params?.limit}
        />
      )}
    </Modal>
  );
};
export default PayoutModal;

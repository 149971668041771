/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {MenuOutlined, UserOutlined} from "@ant-design/icons";
import {Button, Dropdown} from "antd";
import React, {useEffect, useState} from "react";

import {useQuery, useQueryClient} from "@tanstack/react-query";
import {Link} from "react-router-dom";
import {getCurrentUser} from "../../../api/user";
import UseAuth from "../../../hooks/UseAuth";
import LoginModal from "../../login/LoginModal";
import RegisterModal from "../../register/RegisterModal";
import "./User.css";

const User = () => {
  const {auth, setAuth} = UseAuth();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLogingModalVisible, setIsLoginModalVisible] = useState(false);
  const [isRegisterModalVisible, setIsRegisterModalVisible] = useState(false);

  const [user, setUser] = useState(null);

  const queryClient = useQueryClient();

  useEffect(() => {
    // do not change since this is an object
    setIsLoggedIn(auth ? true : false);
    if (auth) {
      setUser(auth);
    } else {
      setUser(null);
    }
  }, [auth]);

  const currentUserQuery = useQuery({
    queryKey: ["currentUser", user?.userId],
    queryFn: getCurrentUser,
    staleTime: 1000 * 60 * 30, // 60
    enabled: auth !== null,
  });

  const handleMenuClick = ({key}) => {
    if (key === "login") {
      setIsLoginModalVisible(true);
    }
  };

  const userName = currentUserQuery.data?.data?.success.data["full_name"];

  const handleLogout = async () => {
    try {
      await setAuth(null);
      queryClient.clear();
    } catch (err) {
      console.log(err);
    }
  };

  const menuItems = isLoggedIn
    ? [
      {
        key: "1",
        label: (
          <div className="user-greeting">
            <div className="welcome-text">Welcome!</div>
            <div className="user-name-text">{userName}</div>
          </div>
        ),
      },
      user.userType === "user"
        ? {
          key: "user",
          label: <Link to="/user/booking">My Booking</Link>,
          onClick: handleMenuClick,
        }
        : {
          key: "admin",
          label: <Link to="/admin/dashboard">Dashboard</Link>,
          onClick: handleMenuClick,
        },
      {
        key: "account",
        label: <Link to="/account">My Account</Link>,
        onClick: handleMenuClick,
      },
      {
        key: "logout",
        label: "Logout",
        onClick: handleLogout,
      },
    ]
    : [
      {
        key: "login",
        label: <span style={{cursor: "pointer"}}>Login</span>,
        onClick: handleMenuClick,
      },
    ];

  const newMenuItem = {
    key: "partner",
    label: <Link to="/partner/dashboard">Partner</Link>,
  }

  const defaultMenuItems = {
    key: "login",
    label: <span style={{cursor: "pointer"}}>Login</span>,
    onClick: handleMenuClick,
  }


  let updatedMenuItems = [];
  if (isLoggedIn) {
    if (user.userType === "user") {
      updatedMenuItems = [
        ...menuItems.slice(0, 2), // Elements before the index
        newMenuItem,                             // New item
        ...menuItems.slice(2),   // Elements after the index
      ];
    } else {
      updatedMenuItems.push(...menuItems)
    }
  } else {
      updatedMenuItems.push(defaultMenuItems)
  }

  return (
    <div className="navbar">
      <div className="navbar-links">
        {auth ? null : (
          <h4 onClick={() => setIsRegisterModalVisible(true)}>
            Become a Partner
          </h4>
        )}
        <Dropdown
          className="dropdown"
          menu={{
            items: updatedMenuItems}}
          overlayClassName="user-dropdown"
          trigger={["click"]}
        >
          {/*<Button icon={<UserOutlined />} shape="circle" />*/}
          <UserOutlined className="icon"/>
        </Dropdown>
        <Dropdown
          className="dropdown"
          overlayClassName="user-dropdown"
          menu={{items: menuItems}}
          trigger={["click"]}
        >
          {/*<Button icon={<UserOutlined />} shape="circle" />*/}
          <Button className="menu-button" icon={<MenuOutlined/>}/>
        </Dropdown>
      </div>

      <LoginModal
        isModalVisible={isLogingModalVisible}
        handleCancel={() => setIsLoginModalVisible(false)}
        setRegisterModalVisible={() => setIsRegisterModalVisible(true)}
      />
      <RegisterModal
        isModalVisible={isRegisterModalVisible}
        handleCancel={() => setIsRegisterModalVisible(false)}
        setLoginModalVisible={() => setIsLoginModalVisible(true)}
      />
    </div>
  );
};

export default User;

import { API_URLS } from "../configs/api.urls";
import { axiosPrivate } from "../configs/axios";

export const getAllStorage = async (queryParams) => {
  return await axiosPrivate({
    url: API_URLS.GET_ALL_STORAGE_URL,
    method: "GET",
    params: queryParams,
  });
};
export const getStorageByUserId = async () => {
  return await axiosPrivate({
    url: API_URLS.GET_STORAGE_URL,
    method: "GET",
  });
};

export const getStorageById = async (id) => {
  return await axiosPrivate({
    url: API_URLS.GET_STORAGE_BY_ID_URL + id,
    method: "GET",
  });
};

export const getStorageNearby = async ({
  longitude,
  latitude,
  date,
  minCapacity,
  page = 1,
  limit = 10,
}) => {
  return await axiosPrivate({
    url: `${API_URLS.GET_STORAGE_NEAR_BY_URL}?longitude=${longitude}&latitude=${latitude}&date=${date}&minCapacity=${minCapacity}&page=${page}&limit=${limit}`,
    method: "GET",
  });
};

export const CreateStorage = async (data) => {
  return await axiosPrivate({
    url: API_URLS.CREATE_STORAGE_URL,
    method: "POST",
    data: data,
  });
};

export const updateStorageStatus = async (data) => {
  return await axiosPrivate({
    url: API_URLS.UPDATE_STORAGE_STATUS_URL,
    method: "PUT",
    data: data,
  });
};
export const updateStorageData = async (id, data) => {
  return await axiosPrivate({
    url: API_URLS.UPDATE_STORAGE_DATA_URL + id,
    method: "PUT",
    data: data,
  });
};

export const updateStorageOpenHours = async (id, data) => {
  return axiosPrivate({
    url: API_URLS.UPDATE_STORAGE_OPEN_HOURS + id,
    method: "PUT",
    data: data,
  });
};

export const updateStoragePricing = async (id, data) => {
  return axiosPrivate({
    url: API_URLS.UPDATE_STORAGE_PRICING + id,
    method: "PUT",
    data: data,
  });
};

export const updateStorageContact = async (id, data) => {
  return axiosPrivate({
    url: API_URLS.UPDATE_STORAGE_CONTACT + id,
    method: "PUT",
    data: data,
  });
};

export const updateStorageImages = async (id, data) => {
  return axiosPrivate({
    url: API_URLS.UPDATE_STORAGE_IMAGES + id,
    method: "PUT",
    data: data,
  });
};

export const getBookingsByStorageId = async () => {
  return axiosPrivate({
    url: API_URLS.GET_STORAGE_URL,
    method: "GET",
  });
};

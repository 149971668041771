/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useQuery } from "@tanstack/react-query";
import { Button, Modal, Skeleton, Space, Tag } from "antd";
import moment from "moment/moment";
import React, { useState } from "react";
import { getAllUsers } from "../../../common/api/user";
import TableAntd from "../../../common/components/table/TableAntd";
import {
  userStatusArray,
  userTypeArray,
} from "../../../common/constant/Constants";
import SearchComponent from "../../../common/search/Search";
import CreateUser from "./CreateUser";
import UserModal from "./UserModal";
import "./Users.css";
import UserMenu from "../../../common/components/menu/UserMenu";

const Users = () => {
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const [userData, setUserData] = useState(null);
  const [isCreateUserModalVisible, setIsCreateUserModalVisible] =
    useState(false);

  const userQuery = useQuery({
    queryKey: ["users", "all", params],
    queryFn: () => getAllUsers(params),
    staleTime: 1000 * 60 * 15, // 15 minutes
  });

  const usersData = userQuery.data?.data?.success.data;
  const usersMeta = userQuery.data?.data?.success.meta;

  const showCreateUserModal = () => {
    setIsCreateUserModalVisible(true);
  };

  const handleCancel = () => {
    setIsCreateUserModalVisible(false);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const columns = [
    {
      title: "User Name",
      dataIndex: "full_name",
      key: "full_name",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (_, { status }) => (
        <Tag
          color={
            status.toUpperCase() === "BLOCKED"
              ? "volcano"
              : status.toUpperCase() === "VERIFIED"
              ? "green"
              : "yellow"
          }
          key={status}
        >
          {status.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: "Registered Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (_, { created_at }) => {
        return moment(created_at).format("YYYY/MM/DD");
      },
    },
    {
      title: "User Type",
      dataIndex: "user_type",
      key: "user_type",
    },
    {
      title: "Action",
      key: "action",
      render: (_, data) => (
        <Space size="middle">
          <Button
            onClick={() => {
              setUserData(data);
              setIsModalVisible(true);
            }}
          >
            View
          </Button>
        </Space>
      ),
    },
  ];

  const handleTableChange = (pagination) => {
    setParams({
      ...params,
      page: pagination.current,
      limit: pagination.pageSize,
    });
  };

  const searchFilters = [
    { key: "name", label: "User Name", type: "input" },
    {
      key: "status",
      label: "User Status",
      type: "select",
      optionArray: userStatusArray,
    },
    {
      key: "userType",
      label: "User Type",
      type: "select",
      optionArray: userTypeArray,
    },
  ];

  const handleSearch = (filterCriteria) => {
    setParams({
      ...params,
      page: 1,
      ...filterCriteria,
    });
  };

  return (
    <div>
      <UserMenu userRole={"admin"} />
      <div className="admin-user-container">
        <div>
          <SearchComponent filters={searchFilters} onSearch={handleSearch} />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <Button
            type="primary"
            onClick={showCreateUserModal}
            style={{ marginTop: "5px" }}
          >
            Add New User
          </Button>
        </div>
        <div className="admin-user-table">
          {userQuery.isLoading ? (
            <Skeleton />
          ) : (
            <TableAntd
              columns={columns}
              data={usersData}
              total={usersMeta?.totalCount}
              handleTableChange={handleTableChange}
              current={params?.page}
              pageSize={params?.limit}
            />
          )}
        </div>
      </div>
      <div>
        <Modal
          className="admin-user-modal"
          // loading={loading}
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
          width="600px"
        >
          <UserModal
            userInfo={userData}
            setIsModalVisible={setIsModalVisible}
          />
        </Modal>
      </div>
      <div>
        <CreateUser
          setIsCreateUserModalVisible={showCreateUserModal}
          handleCancel={handleCancel}
          isCreateUserModalVisible={isCreateUserModalVisible}
        />
      </div>
    </div>
  );
};

export default Users;

/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {useQuery} from "@tanstack/react-query";
import {Tag} from "antd";
import moment from "moment/moment";
import React, {useState} from "react";
import {GetTransactionsByUserId} from "../../../common/api/transaction";
import TableAntd from "../../../common/components/table/TableAntd";
import {paymentCategoryArray, transationStatusArray,} from "../../../common/constant/Constants";
import SearchComponent from "../../../common/search/Search";
import UserMenu from "../../../common/components/menu/UserMenu";

const PartnerTransaction = () => {
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });

  const transationQuery = useQuery({
    queryKey: ["transaction", "user", params],
    queryFn: () => GetTransactionsByUserId(params),
    staleTime: 1000 * 60 * 15, // 15 minutes
  });

  const payoutData = transationQuery.data?.data?.success.data;
  const payoutMeta = transationQuery.data?.data?.success.meta;

  const columns = [
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (_, { created_at }) => {
        return moment(created_at).format("YYYY/MM/DD");
      },
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Total Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (_, { status }) => (
        <Tag
          color={
            status === "pending"
              ? "yellow"
              : status === "succeeded"
              ? "green"
              : "volcano"
          }
          key={status}
        >
          {status.toUpperCase()}
        </Tag>
      ),
    },
  ];

  const handleTableChange = (pagination) => {
    setParams({
      ...params,
      page: pagination.current,
      limit: pagination.pageSize,
    });
  };

  const searchFilters = [
    {
      key: "category",
      label: "Transation Category",
      type: "select",
      optionArray: paymentCategoryArray,
    },
    {
      key: "status",
      label: "Status",
      type: "select",
      optionArray: transationStatusArray,
    },
  ];

  const handleSearch = (filterCriteria) => {
    setParams({ ...params, page: 1, ...filterCriteria });
  };

  return (
    <div>
      <UserMenu userRole={"partner"} />
      <div className="booking-container">
        <div>
          <SearchComponent filters={searchFilters} onSearch={handleSearch} />
        </div>
        <div className="booking-table">
          <TableAntd
            columns={columns}
            data={payoutData}
            total={payoutMeta?.totalCount}
            handleTableChange={handleTableChange}
            current={params?.page}
            pageSize={params?.limit}
          />
        </div>
      </div>
    </div>
  );
};

export default PartnerTransaction;
